import React from 'react';
import moment from 'moment';
import Service from '../../../../config/networkutils';
import { withStyles } from '@material-ui/core/styles';
import { getCookie } from '../../../../utils/CookieHelper';
import RolesEmployeeLocation from './RolesEmployeeLocation';
import { LoopCircleLoading } from 'react-loadingg';
import { withSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography';
import { getCRM, getCRMTableData, getEmployeeRegionWithHistory, getEmployees, getEmployeesLeaves, getOtherTasks, getOwnerDelivery, getActiveRoles, getSubSector, getRegion, getGeneralforemanGang } from '../../../../config/PromisesHandler';
// import { roles_crm_emp } from '../../../../config/apiList';
// import { Grid, Switch } from '@material-ui/core';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Slide } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import UserAllocation from '../../../UserAllocation/UserAllocation';

const months = { 0: 'January', 1: 'Febrauary', 2: 'March', 3: 'April', 4: 'May', 5: 'June', 6: 'July', 7: 'August', 8: 'September', 9: 'October', 10: 'November', 11: 'December' }
const token = getCookie("ptd_ts_token");

const useStyles = (theme) => ({
    accordianColor: {
        backgroundColor: "#EDFAFF",
    }
})

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
class ResourceLocation extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            allRoles: [],
            rolesObj: {},
            allEmp: [],
            allEmpRegionHistory: [],
            regionObj: {},
            isLoading: true,
            allProjects: [],
            responseData: [],
            projectObj: {},
            selectedRole: [],
            rolesData: [],
            searchRerenders: false,
            allSubSectors: [],
            allOwnerRegions: [],
            selectedOwnerRegion: [],
            allOwnerDeliveryUnits: [],
            selectedOwnerDelivery: [],
            allElectricityOHLTcRMs: [],
            showRole: false,
            showRoleIndex: null,
            selectedCrm: [{ name: '', id: '' },],
            selectedEmployee: { first_name: 'Select Employee', last_name: '', id: '' },
            splittedRoleEmployees: [],
            combineRoles: false,
            gangMen: [],
            allocationDialog: false,
            allocationDialogSize: 'xs',
            allocationData: null,
            type: 'week',
            selectedStartDate: moment().subtract(2, 'months').startOf('month'),
            selectedEndDate: moment().add(6, 'months').endOf('month'),
        }
        this.refresh = this.refresh.bind(this)
        this.getDialogSize = this.getDialogSize.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.addAllocation = this.addAllocation.bind(this);
        this.callMakeReport = this.callMakeReport.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
    }

    editedScheduleUIUpdate = (empArray) => {
        // Filter the response data based on the employee
        let allRoleEmployeess = [...this.state.responseData];
        for (let crmIndex = 0; crmIndex < allRoleEmployeess.length; crmIndex++) {
            for (let empIndex = 0; empIndex < empArray.length; empIndex++) {
                if ((allRoleEmployeess[crmIndex].crm === empArray[empIndex].crm) && (allRoleEmployeess[crmIndex].emp_id === empArray[empIndex].emp_id)) {
                    allRoleEmployeess[crmIndex] = empArray[empIndex];
                }
            }
        }
        this.setState({
            ...this.state,
            allRoleEmployees: allRoleEmployeess,
        });
    };

    handleTypeChange = (type, startDate, endDate) => {
        this.setState({ type: type, selectedStartDate: startDate, selectedEndDate: endDate }, () => {
            this.getProjectsEmployees(startDate, endDate)
        })
    }

    refresh() {
        this.getProjectsEmployees(this.state.selectedStartDate, this.state.selectedEndDate)
    }

    callMakeReport(startDate, endDate) {
        this.setState({ selectedStartDate: startDate, selectedEndDate: endDate }, () => {
            this.getProjectsEmployees(startDate, endDate)
        })
    }

    getProjectsEmployees(startDate, endDate) {
        this.setState({ isLoading: true })

        let api = '/api/prp/rolenew/get_staff_location_data/?archive_reason=0&general_foreman=' + this.props.gf.id


        let selectedEmployeesIds = []
        let selectedCrmsIds = []
        if (this.props.selectedEmployees) {
            this.props.selectedEmployees.map(emp => selectedEmployeesIds.push(emp.employee_id))
        }
        if (this.props.selectedCrms) {
            this.props.selectedCrms.map(crm => selectedCrmsIds.push(crm.crm_id))
        }
        if (this.props.selectedEmployees.length > 0 && this.props.selectedCrms.length > 0) {
            api += `&emp=${selectedEmployeesIds}&crm=${selectedCrmsIds}`
        } else if (this.props.selectedEmployees.length > 0 || this.props.selectedCrms.length > 0) {
            if (this.props.selectedCrms.length > 0) {
                api += `&crm=${selectedCrmsIds}`
            } else if (this.props.selectedEmployees.length > 0) {
                api += `&emp=${selectedEmployeesIds}`
            }
        } else {
            if (this.state.selectedCrm?.id) {
                api += `&crm=${this.state.selectedCrm.id}`
            }
            if (this.state.selectedEmployee?.id) {
                api += `&emp=${this.state.selectedEmployee.id}`
            }
        }
        if (this.state.type === 'month') {
            api += `&change_report_type=month`
        } else if (this.state.type === 'quarter') {
            api += `&change_report_type=quarter`
        }

        if (startDate && endDate) {
            api += `&from_date=${moment(startDate).format("YYYY-MM-DD")}&to_date=${moment(endDate).format('YYYY-MM-DD')}`
        }

        Service.get(api, {
            headers: { Authorization: "Token " + token, }
        })
            .then(res => {
                this.setState({
                    responseData: res.data.data,
                }, () => {
                    if (this.props.getDates) {
                        this.props.getDates(moment(startDate).format("YYYY-MM-DD"), moment(endDate).format('YYYY-MM-DD'))
                    }
                    this.makeReport(res.data.data, startDate, endDate, res.data.all_dates, this.state.type)
                })
            }).catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' })
                this.setState({ isLoading: false })
            })
    }

    componentDidMount() {
        Promise.all([
            getActiveRoles(),
            getEmployees(),
            getEmployeeRegionWithHistory(),
            getRegion(),
            getCRM(),
            getCRMTableData(),
            getSubSector(),
            getOwnerDelivery(),
            getOtherTasks(),
            getEmployeesLeaves(),
            getGeneralforemanGang(),
        ])
            .then(res => {
                let gangMen = res[10].filter(gm => gm.general_foreman === this.props.gf.id)
                let data = res[0]
                let rolesobj = {}
                data.forEach(ele => {
                    let newData = { [ele.id]: ele.job_roles }
                    rolesobj = { ...rolesobj, ...newData }
                })

                let regionObj = {}
                res[3].forEach(ele => {
                    let newData = { [ele.id]: ele.name }
                    regionObj = { ...regionObj, ...newData }
                })
                let projectObj = {}
                res[4].forEach(ele => {
                    let newData = { [ele.id]: { project_code: ele.project_code, name: ele.name } }
                    projectObj = { ...projectObj, ...newData }
                })
                let othertaskObject = {}
                res[8].forEach(ele => {

                    let newData = { [ele.id]: ele.name }
                    othertaskObject = { ...othertaskObject, ...newData }
                })

                let live = []
                for (let index = 0; index < res[4].length; index++) {
                    const element = res[4][index];
                    if (element.archive_reason === 0) {
                        live.push(element)
                    }
                }

                this.setState({
                    rolesObj: rolesobj,
                    allRoles: res[0],
                    allEmp: res[1],
                    allEmpRegionHistory: res[2],
                    allOwnerRegions: res[3],
                    regionObj: regionObj,
                    allProjects: live,
                    projectObj: projectObj,
                    isLoading: false,
                    completedOppertunities: res[5],
                    allSubSectors: res[6],
                    allOwnerDeliveryUnits: res[7],
                    allOtherTasks: res[8],
                    othertaskObject,
                    allEmployeesLeaves: res[9],
                    gangMen
                }, () => {

                    let searchParams = new URLSearchParams(window.location.search)

                    let selectedRoles = []
                    let selectedRegions = []
                    let selectedSubSectors = []
                    let selectedOwnerDeliverys = []
                    let selectedCrm = []
                    let selectedEmployee = []

                    if (searchParams.get("role")) {
                        let idNum = searchParams.get("role").split(',')
                        for (let id = 0; id < idNum.length; id++) {
                            let perRole = this.state.allRoles.filter((rol) => rol.id === parseInt(idNum[id]))
                            selectedRoles.push(perRole[0])
                        }
                    }

                    if (searchParams.get("employee")) {
                        let idNum = searchParams.get("employee")
                        selectedEmployee = this.state.allEmp.filter(emp => emp.id === parseInt(idNum))[0]
                    } else {
                        selectedEmployee = { first_name: 'Select Employee', last_name: '', id: '' }
                    }
                    if (searchParams.get("crm")) {
                        let idNum = searchParams.get("crm")
                        selectedCrm = this.state.allProjects.filter(crm => crm.id === parseInt(idNum))[0]
                    }
                    if (selectedCrm || selectedEmployee) {
                        this.setState({
                            selectedCrm, selectedEmployee, isLoading: true
                        }, () => this.getProjectsEmployees(this.state.selectedStartDate, this.state.selectedEndDate))
                    }
                    else {
                        this.setState({
                            selectedRole: selectedRoles, selectedOwnerRegion: selectedRegions
                            , selectedSubSector: selectedSubSectors, selectedOwnerDelivery: selectedOwnerDeliverys, isLoading: true
                        }, () => this.getProjectsEmployees(this.state.selectedStartDate, this.state.selectedEndDate))
                    }
                })
            })
            .catch(e => {
                console.log(e)
                this.props.enqueueSnackbar('Something went wrong!.', {
                    variant: 'error'
                });
            })

    }

    makeReport(allRoles, selectedStartDate, selectedEndDate, datesArray, type) {
        let splittedRoleEmployees = []
        let combinedRoleEmployees = []
        let templateRoles = []
        let allMonths = []
        let finalColumns = []

        let startOfMonth;
        let endOfMonth;

        templateRoles = [...allRoles]
        if (selectedStartDate && selectedEndDate) {
            startOfMonth = moment(selectedStartDate).startOf('month');
            endOfMonth = moment(selectedEndDate).endOf('month');
        } else {
            startOfMonth = this.state.selectedStartDate ? moment(this.state.selectedStartDate).startOf('month') : moment().subtract(2, 'months').startOf('month');
            endOfMonth = this.state.selectedEndDate ? moment(this.state.selectedEndDate).endOf('month') : moment().add(6, 'months').endOf('month');
        }

        let startDate = moment(startOfMonth)//moment('2012-04-01');
        let endDate = moment(endOfMonth)//moment('2014-11-01');

        let result = [];
        if (endDate.isBefore(startDate)) {
            throw new Error("End date must be greated than start date.")
        }

        while (startDate.isBefore(endDate)) {
            result.push(months[startDate.month()] + ' ' + startDate.year());
            startDate.add(1, 'month');
        }

        // let allWeekDates = weekStartDates(startOfMonth, endOfMonth);



        // let final = []
        // let colspan = 1;
        // for (let i = 0; i < allWeekDates.length; i++) {

        //     let date = moment(allWeekDates[i], 'DD/MM/YYYY')
        //     let monthYear = months[date.month()] + ' ' + date.year()
        //     if (!result.length) {
        //         result.push(monthYear)
        //         final.push({ month: monthYear, colSpan: colspan })
        //     }
        //     else {
        //         if (result[result.length - 1] === monthYear) {
        //             colspan += 1
        //             final[final.length - 1]['colSpan'] = colspan
        //         }
        //         else {
        //             colspan = 1
        //             result.push(monthYear)
        //             final.push({ month: monthYear, colSpan: colspan })
        //         }
        //     }

        // }



        let assignedUserIds = []
        let unAssignedUsers = []
        for (let i = 0; i < templateRoles.length; i++) {
            const element = templateRoles[i];
            if (!assignedUserIds.includes(element.emp_id)) {
                assignedUserIds.push(element.emp_id)
            }
        }

        for (let i = 0; i < this.state.gangMen.length; i++) {
            const element = this.state.gangMen[i];
            if (!assignedUserIds.includes(element.ptd_employee)) {
                element.emp_id = element.ptd_employee
                element.dates = {}
                unAssignedUsers.push(element)
            }
        }
        templateRoles = templateRoles.concat(unAssignedUsers)
        for (let item = 0; item < templateRoles.length; item++) {
            const roleItem = templateRoles[item];
            if (!roleItem.dates) {
                roleItem.dates = {}
            }
        }
        let filterEmp = []

        for (let index = 0; index < allRoles.length; index++) {
            const element = allRoles[index];

            if (selectedStartDate && selectedEndDate) {
                startOfMonth = moment(selectedStartDate).startOf('month');
                endOfMonth = moment(selectedEndDate).endOf('month');
            } else {
                startOfMonth = this.state.selectedStartDate ? moment(this.state.selectedStartDate).startOf('month') : moment().subtract(2, 'months').startOf('month');
                endOfMonth = this.state.selectedEndDate ? moment(this.state.selectedEndDate).endOf('month') : moment().add(6, 'months').endOf('month');
            }

            let startDate = moment(startOfMonth)
            let endDate = moment(endOfMonth)

            let result = [];

            if (endDate.isBefore(startDate)) {
                throw new Error("End date must be greater than start date.")
            }

            while (startDate.isBefore(endDate)) {
                result.push(months[startDate.month()] + ' ' + startDate.year());
                startDate.add(1, 'month');
            }

            // let allWeekDates = weekStartDates(startOfMonth, endOfMonth);

            let final = []
            let colspan = 1;
            // for (let i = 0; i < allWeekDates.length; i++) {

            //     let date = moment(allWeekDates[i], 'DD/MM/YYYY')
            //     let monthYear = months[date.month()] + ' ' + date.year()
            //     if (!result.length) {
            //         result.push(monthYear)
            //         final.push({ month: monthYear, colSpan: colspan })
            //     }
            //     else {
            //         if (result[result.length - 1] === monthYear) {
            //             colspan += 1
            //             final[final.length - 1]['colSpan'] = colspan
            //         }
            //         else {
            //             colspan = 1
            //             result.push(monthYear)
            //             final.push({ month: monthYear, colSpan: colspan })
            //         }
            //     }

            // }

            if (type === 'week') {
                for (let i = 0; i < datesArray.length; i++) {
                    // debugger
                    let date = moment(datesArray[i], 'DD/MM/YYYY')
                    let monthYear = months[date.month()] + ' ' + date.year()
                    if (!result.length) {
                        result.push(monthYear)
                        final.push({ month: monthYear, colSpan: colspan })
                    }
                    else {
                        if (result[result.length - 1] === monthYear) {
                            colspan += 1
                            final[final.length - 1]['colSpan'] = colspan
                        }
                        else {
                            colspan = 1
                            result.push(monthYear)
                            final.push({ month: monthYear, colSpan: colspan })
                        }
                    }

                }
            } else if (type === 'month') {
                for (let i = 0; i < datesArray.length; i++) {
                    let date = moment(datesArray[i])
                    if (final.map((ele) => ele.month).indexOf(date.year()) === -1) {
                        final.push({ month: date.year(), colSpan: 1 })
                    } else {
                        final[final.map((ele) => ele.month).indexOf(date.year())]['colSpan'] += 1
                    }

                }

            } else {
                for (let i = 0; i < datesArray.length; i++) {
                    let year = datesArray[i].split('-')[1]
                    if (final.map((ele) => ele.month).indexOf(year) === -1) {
                        final.push({ month: year, colSpan: 1 })
                    } else {
                        final[final.map((ele) => ele.month).indexOf(year)]['colSpan'] += 1
                    }

                }
            }

            for (let index = 0; index < final.length; index++) {
                const element = final[index];
                element.width = 100 * element.colSpan
            }

            allMonths = final

            let weeks = [];
            let weekdata = [];
            for (let i = 0; i < element.project_details.length; i++) {
                let roleItem = element.project_details[i];
                for (let m = 0; m < datesArray.length; m++) {
                    let date = datesArray[m];
                    if (roleItem.dates.hasOwnProperty(date) && roleItem.dates[date] !== 0) {
                        if (weeks.indexOf(date) === -1) {
                            weeks.push(date);
                            weekdata.push({ name: [roleItem.crm_name], project_code: [[roleItem.crm_project_code]], crmId: [roleItem.crm], crm_color_code: [roleItem.crm_color_code], })
                        } else {
                            if (weekdata[weeks.indexOf(date)]['name'] !== "") {
                                weekdata[weeks.indexOf(date)]['name'].push(roleItem.crm_name)
                                weekdata[weeks.indexOf(date)]['project_code'].push(roleItem.crm_project_code)
                                weekdata[weeks.indexOf(date)]['crmId'].push(roleItem.crm)
                                weekdata[weeks.indexOf(date)]['crm_color_code'].push(roleItem.crm_color_code)
                            } else if (weekdata[weeks.indexOf(date)]['name'] === "") {
                                weekdata[weeks.indexOf(date)]['name'].push(this.state.projectObj[roleItem.crm_name])
                                weekdata[weeks.indexOf(date)]['project_code'].push(roleItem.crm_project_code)
                                weekdata[weeks.indexOf(date)]['crmId'].push(roleItem.crm)
                                weekdata[weeks.indexOf(date)]['crm_color_code'].push(roleItem.crm_color_code)
                            }
                        }
                    }
                    else {
                        if (weeks.indexOf(date) === -1) {
                            weeks.push(date);
                            weekdata.push({ name: [], crmId: [], project_code: [], crm_color_code: [] })
                        }
                    }
                }
            }

            if (element.project_details.length === 0) {
                for (let m = 0; m < datesArray.length; m++) {
                    let date = datesArray[m];
                    weeks.push(date);
                    weekdata.push({ name: [], crmId: [], project_code: [], crm_color_code: [] })
                }
            }

            element['weeks'] = weeks;
            element['weekdata'] = weekdata
            if (element.weekdata.length > 0) {
                if ((!element.end_date ? true : moment(element.end_date).isAfter(moment().format()))) {
                    filterEmp.push(element)
                }
            }

            let columns = []
            for (let i = 0; i < datesArray.length; i++) {
                let newObj = {
                    title: type === "week" ? moment(datesArray[i], 'DD/MM/YYYY').format('DD MMM') : type === "month" ? moment(datesArray[i]).format('MMM YYYY') : type === "quarter" ? datesArray[i] : '',
                    field: datesArray[i],
                    type: 'number',
                    width: 100,
                    sorting: false,
                    editable: 'never',
                    filtering: false
                }
                columns.push(newObj)
            }
            finalColumns = columns

        }

        if (filterEmp.length > 0) {
            combinedRoleEmployees = [...combinedRoleEmployees, ...filterEmp]
            splittedRoleEmployees.push({ allEmpCopy: filterEmp, allEmp: filterEmp, columns: finalColumns, allMonths: allMonths })
        }

        this.setState({ splittedRoleEmployees, templateRoles, combinedRoleEmployees }, () => {
            this.setState({
                isLoading: false
            })

        })
    }

    openRole = (roleIndex) => { this.setState({ showRole: (!this.state.showRole), showRoleIndex: roleIndex }) }

    getDialogSize = (size) => {
        this.setState({ allocationDialogSize: size })
    }

    closeDialog = () => {
        this.setState({ allocationDialog: false, allocationDialogSize: "xs", allocationData: null }, () => {
            if (this.state.selectedCrm?.id || this.state.selectedEmployee?.id) {
                this.setState({ isLoading: true, splittedRoleEmployees: [] }, () => {
                    this.getProjectsEmployees(this.state.selectedStartDate, this.state.selectedEndDate)
                })
            } else {
                this.setState({ isLoading: true, splittedRoleEmployees: [] }, () => {
                    this.getProjectsEmployees(this.state.selectedStartDate, this.state.selectedEndDate)
                })
            }
        })
    }

    addAllocation = (emp, prj) => {
        emp['employee_id'] = emp?.employee_id ? emp?.employee_id : emp?.id
        let allocationData = { emp: emp, prj: prj }
        this.setState({ allocationData: allocationData }, () => {
            this.setState({ allocationDialog: true, allocationDialogSize: "xs" })
        })
    }

    render() {
        // const classes = this.props.classes;

        const handleClose = () => {
            this.setState({ allocationDialog: false, allocationDialogSize: "xs", allocationData: null })
        };

        return (<div>

            {this.state.isLoading ? <LoopCircleLoading color='#005D99' /> :
                <>
                    {/* <Grid container justifyContent='space-around' alignItems='center'>
                        <Grid>
                            {this.state.combineRoles ? <Typography variant={'h6'}>All gang members</Typography> : <Typography variant={'h6'}>Roles</Typography>}
                        </Grid>
                        <Grid>

                            <Switch
                                checked={this.state.combineRoles}
                                onChange={(e) => {
                                    this.setState({ combineRoles: e.target.checked })
                                }}
                                name="CombineRoles"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                        </Grid>
                    </Grid> */}
                    {this.state.combineRoles ? <RolesEmployeeLocation
                        roleData={{ allEmpCopy: this.state.combinedRoleEmployees, allEmp: this.state.combinedRoleEmployees, columns: this.state.splittedRoleEmployees.length > 0 ? this.state.splittedRoleEmployees[0]['columns'] : [], allMonths: this.state.splittedRoleEmployees.length > 0 ? this.state.splittedRoleEmployees[0]['allMonths'] : [] }}
                        projectObj={this.state.projectObj}
                        // role={this.state.reportedRoles[roleIndex]}
                        templateRoles={this.state.responseData}
                        allOtherTasks={this.state.allOtherTasks}
                        allEmployeesLeaves={this.state.allEmployeesLeaves}
                        othertaskObject={this.state.othertaskObject}
                        allProjects={this.state.allProjects}
                        allRoles={this.state.allRoles}
                        rolesObj={this.state.rolesObj}
                        updateTheUpdatedSchedules={this.editedScheduleUIUpdate}
                        gangMen={this.state.gangMen}
                        addAllocation={this.addAllocation}
                        refresh={this.refresh}
                        callMakeReport={this.callMakeReport}
                        selectedStartDate={this.state.selectedStartDate}
                        selectedEndDate={this.state.selectedEndDate}
                        handleTypeChange={this.handleTypeChange}
                        type={this.state.type}
                    />
                        :
                        <>
                            {this.state.splittedRoleEmployees.length > 0 ?
                                <div>
                                    {this.state.splittedRoleEmployees.map((role, roleIndex) =>
                                        <RolesEmployeeLocation
                                            roleData={role}
                                            projectObj={this.state.projectObj}
                                            // role={this.state.reportedRoles[roleIndex]}
                                            templateRoles={this.state.responseData}
                                            allOtherTasks={this.state.allOtherTasks}
                                            allEmployeesLeaves={this.state.allEmployeesLeaves}
                                            othertaskObject={this.state.othertaskObject}
                                            allProjects={this.state.allProjects}
                                            allRoles={this.state.allRoles}
                                            rolesObj={this.state.rolesObj}
                                            updateTheUpdatedSchedules={this.editedScheduleUIUpdate}
                                            combineRoles={this.combineRoles}
                                            refresh={this.refresh}
                                            gangMen={this.state.gangMen}
                                            addAllocation={this.addAllocation}
                                            callMakeReport={this.callMakeReport}
                                            handleTypeChange={this.handleTypeChange}
                                            type={this.state.type}
                                            selectedStartDate={this.state.selectedStartDate}
                                            selectedEndDate={this.state.selectedEndDate}
                                        />
                                    )}
                                </div> : <Box display="flex" justifyContent="center" alignItems="center">No Data is Present for the Selected Filters</Box>}
                        </>}
                </>
            }
            <Dialog
                fullWidth
                maxWidth={this.state.allocationDialogSize}
                open={this.state.allocationDialog}
                TransitionComponent={Transition}
                disableEnforceFocus >
                <DialogTitle>
                    <Box display={'flex'} flexDirection={'row'} width={'100%'} alignItems={'center'}>
                        <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                            {!this.state.allocationData ? "Add Employee" : "Add Resource to " + this.state.allocationData?.emp?.first_name + " " + this.state.allocationData?.emp?.last_name}
                        </Typography>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <UserAllocation
                        type={'gang_structures'}
                        getDialogSize={this.getDialogSize}
                        closeDialog={this.closeDialog}
                        allocationData={this.state.allocationData}
                        general_foreman={this.props.gf.id}
                        selectedGang={this.props.selectedGang} />
                </DialogContent>
            </Dialog>
        </div >)
    }
}

export default withSnackbar(withStyles(useStyles)(ResourceLocation))