import React, { createRef } from 'react';
import moment from 'moment';
import { withSnackbar } from "notistack";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import { DatePicker } from "@material-ui/pickers";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';

import EmployeeRow from "./EmployeeRow";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {
  weekStartDates,
} from "../../../../utils/betweenDates";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import Service from '../../../../config/networkutils';
import {
  prp_roles_api,
  bulkPRPTandPRPRcreate,
  // employees_and_projects,
  roles_crm_emp,
} from '../../../../config/apiList';
import { getCookie } from '../../../../utils/CookieHelper';
import {
  filterStandByEmps,
  getEmployeeID,
  // getfilteredByAnotherArrayNotContains, 
  groupByField, sortByGivenOrder
} from '../../../../utils/reuseFunctions';
// import { prprBulkUpdate } from '../../../../config/PromisesHandler';
import { ohlSortOrder } from '../../../../config/abstractedVariables';
import { LoopCircleLoading, RotateCircleLoading } from 'react-loadingg';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import ZoomIn from '@material-ui/icons/ZoomIn';
import ZoomOut from '@material-ui/icons/ZoomOut';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const token = getCookie("ptd_ts_token");
// var scroll = 0;

function screenSizeCheck() {
  if (window.innerWidth <= 1650) {
    // alert(window.innerWidth)
    // 1536
    // 1366
    //low <=1366
    //medium <=1650
    //high > 1650
    return true
  } else {
    // alert(window.innerWidth)
    return false
  }
}

const useStyles = (theme) => ({
  table: {
    minWidth: 650,
    "& .MuiTableCell-root": {
      borderLeft: "1px solid rgba(224, 224, 224, 1)"
    }
  },
  root: {
    width: 'calc(100vw - 35px)',
  },
  container: {
    // maxHeight: 440,
  },
  topSticky: {
    position: "sticky",
    top: '0px',
    background: "	#F8F8F8",
    boxShadow: "5px 2px 5px grey",
  },
  topStickyDates: {
    position: "sticky",
    top: '68px',
    background: "	#F8F8F8",
    boxShadow: "5px 2px 5px grey",
  },
  smallIcon: {
    padding: '5px'
  }
});

// const months = { 0: 'January', 1: 'Febrauary', 2: 'March', 3: 'April', 4: 'May', 5: 'June', 6: 'July', 7: 'August', 8: 'September', 9: 'October', 10: 'November', 11: 'December' }
class RolesEmployeeLocation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      allRoles: [],
      rolesObj: {},
      allEmp: [],
      empObj: {},
      columns: [],
      allMonths: [],
      selectedStartDate: props.selectedStartDate,
      selectedEndDate: props.selectedEndDate,
      templateRoles: [],
      selectedEmployee: "",
      employeeSearchField: "",
      employeeSearchFieldRerender: false,
      rawEmployeeSchedules: [],
      tabcelEdit: { empIndex: null, weekIndex: 0, showInputBox: false },
      editedWeekObj: {},
      selectedEmpRows: [],
      assignSelectedOpen: false,
      assignFromDate: "",
      assignToDate: "",
      assignQty: "",
      assignDates: {},
      selectedCrm: "",
      isLoading: false,
      searchLoading: false,
      extend: 6,
      reduce: 2,
      startDetect: false,
      endDetect: false,
      scroll: 0,
      work_typeObj: {},
      work_TypeFullObj: {},
      zoom: 1,
    }
    this.ref = createRef()
    this.refWidth = createRef()
    this.childRef = createRef()
    this.refresh = this.refresh.bind(this)
    this.addAllocation = this.addAllocation.bind(this)
  }

  workTypeObj() {
    var obj = {}
    var obj1 = {}
    Service.get('/api/worktype/', {
      headers: {
        Authorization: "Token " + token,
      },
    }).then(res => {
      for (let index = 0; index < res.data.length; index++) {
        const element = res.data[index];
        obj[element.id] = element.short_form;
        obj1[element.id] = element.name;
      }
      this.setState({
        work_typeObj: obj,
        work_TypeFullObj: obj1
      })
    }).catch(error => {
      this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' })
    })

  }

  componentDidMount() {
    this.workTypeObj()
    let { allEmp } = this.props.roleData;
    for (let index = 0; index < allEmp.length; index++) {
      const element = allEmp[index];
      element.is_checked = false
    }
    let sortedObj = sortByGivenOrder(ohlSortOrder, allEmp, this.props.rolesObj, "job_title")
    this.setState({ templateRoles: this.props.templateRoles, allEmpCopy: sortedObj.sorted, allEmp: sortedObj.sorted }, () => {
      // this.makeReport() 
    })
    this.setState({ columns: this.props.roleData.columns, allMonths: this.props.roleData.allMonths })
    // this.setState({ templateRoles: this.props.templateRoles, allEmpCopy: sortedObj.sorted, allEmp: sortedObj.sorted, columns, allMonths })
  }

  refresh() {
    if (this.props.refresh) {
      this.props.refresh()
    }
  }

  handleDateChange1 = (d) => {
    this.setState({ selectedStartDate: d })
  }

  handleDateChange2 = (d) => {
    this.setState({ selectedEndDate: d })
  }

  filterByName = (e) => {
    let allEmps = [...this.state.allEmp];

    let filteredByEmployees;

    filteredByEmployees = allEmps.filter(
      (emp) =>
        emp.first_name
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        emp.last_name
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
    );

    if (e.target.value === "") {
      this.setState({
        allEmp: this.state.allEmpCopy,
        employeeSearchField: e.target.value,
        searchLoading: true
      }, () => { this.setState({ searchLoading: false }) });
    } else if (filteredByEmployees.length >= 1) {
      this.setState({
        allEmp: filteredByEmployees,
        employeeSearchField: e.target.value,
        searchLoading: true
      }, () => { this.setState({ searchLoading: false }) });
    } else {
      this.props.enqueueSnackbar(
        "No Employees for the search!",
        {
          variant: "error",
        }
      );
    }
  }

  updateEmployeeRow = (empRowData, index) => {
    let allempp = [...this.state.allEmp]
    allempp[index] = empRowData
    this.setState({ allEmp: allempp })
  }

  handleCustomFromDate = (date) => {
    if (date.toString().slice(0, 3) === "Mon") {
      this.setState({
        assignFromDate: date.toISOString().slice(0, 10) + "T00:00:00.000Z",
      });
    } else {
      this.props.enqueueSnackbar("You are allowed to select only Mondays!!!", {
        variant: "error",
      });
    }
  };

  handleCustomToDate = (date) => {
    if (moment(this.state.assignFromDate).isAfter(moment(date))) {
      this.props.enqueueSnackbar(
        "From date is higher than To date!!!", {
        variant: "error",
      }
      )
      return
    }
    if (date.toString().slice(0, 3) === "Mon") {
      this.setState({
        assignToDate: date.toISOString().slice(0, 10) + "T00:00:00.000Z",
      });
    } else {
      this.props.enqueueSnackbar("You are allowed to select only Mondays!!!", {
        variant: "error",
      });
    }
  };

  handleCustomQty = (value) => {
    let dates = {};
    if (value) {
      value = parseFloat(value)
      if (this.state.assignFromDate && this.state.assignToDate) {
        let allMondayDates = weekStartDates(this.state.assignFromDate, this.state.assignToDate)
        let scheduleTotal = allMondayDates.length * value;
        for (let dateIndex = 0; dateIndex < allMondayDates.length; dateIndex++) {
          const element = allMondayDates[dateIndex];
          dates = { ...dates, [element]: value }
        }

        this.setState({
          assignDates: dates, assignQty: value, scheduleTotal
        });

      } else {
        this.props.enqueueSnackbar("Select From and To dates", {
          variant: "error",
        });

      }
    } else {

      this.setState({
        assignDates: dates, assignQty: value
      });
    }
  };

  handleAllRowSelected = (selectStatus) => {
    let newSelectedEmpRows = [];
    if (selectStatus) {
      for (let index = 0; index < this.state.allEmp.length; index++) {
        const element = this.state.allEmp[index];
        newSelectedEmpRows.push(element)
      }
      this.state.allEmp.forEach((emp) => {
        emp.is_checked = true
      })
    } else {
      newSelectedEmpRows = []
      this.state.allEmp.forEach((emp) => {
        emp.is_checked = false
      })
    }
    this.setState({ selectedEmpRows: newSelectedEmpRows, }, () => {
    })
  }

  updateSelected = (rowEmp, selectStatus) => {
    let newSelectedEmpRows = [...this.state.selectedEmpRows]
    let allEmployee = JSON.parse(JSON.stringify(this.state.allEmp))
    if (selectStatus) {
      newSelectedEmpRows.push(rowEmp)
      for (let index = 0; index < allEmployee.length; index++) {
        const element = allEmployee[index];
        if (element.hasOwnProperty('employee_id')) {
          if (element.employee_id === rowEmp.employee_id && element.role_id === rowEmp.role_id) {
            element.is_checked = true
          }
        }
        if (element.hasOwnProperty('id')) {
          if (element.id === rowEmp.id && element.role_id === rowEmp.role_id) {
            element.is_checked = true
          }
        }
      }
    } else {
      newSelectedEmpRows = newSelectedEmpRows.filter((emp) => emp.id !== rowEmp.id || emp.employee_id !== rowEmp.employee_id)
      for (let index = 0; index < allEmployee.length; index++) {
        const element = allEmployee[index];
        if (element.hasOwnProperty('employee_id')) {
          if (element.employee_id === rowEmp.employee_id && element.role_id === rowEmp.role_id) {
            element.is_checked = false
          }
        }
        if (element.hasOwnProperty('id')) {
          if (element.id === rowEmp.id && element.role_id === rowEmp.role_id) {
            element.is_checked = false
          }
        }

      }
    }
    this.setState({ allSelected: true, selectedEmpRows: newSelectedEmpRows, allEmp: allEmployee })
  }

  postGangOps(data) {
    return new Promise((resolve, reject) => {
      Service.post(prp_roles_api, {
        headers: {
          Authorization: "Token " + token,
        },
        data,
      })
        .then(res => {
          resolve(res.data)
        })
        .catch(error => reject(error))
    })
  }

  postGangsOps(data) {
    return new Promise((resolve, reject) => {
      Service.post(bulkPRPTandPRPRcreate, {
        headers: {
          Authorization: "Token " + token,
        },
        data,
      })
        .then(res => {
          resolve(res.data)
        })
        .catch(error => reject(error))
    })
  }

  filterExistingPrpt() {
    let sortedDataCopy = [...this.state.sortedData]
    let descipline = "Electricity OHLT"
    let prpCreate = []
    let prpExist = []

    this.state.selectedGangMembers.forEach(gangMem => {
      let equal = false;
      let existRole = {};
      for (let PrpTemplateIndex = 0; PrpTemplateIndex < sortedDataCopy.length; PrpTemplateIndex++) {
        const prpt = sortedDataCopy[PrpTemplateIndex];

        if ((prpt.role === gangMem.ptd_employee_data.job_title) && (this.props.allSubSectorsLupObj[prpt.descipline]) === descipline) {
          equal = true
          existRole = prpt
          break
        }
      }

      if (equal) {
        prpExist.push({ gangMem, existRole, })

      } else {

        prpCreate.push(gangMem)
      }

    }
    )
    let filterExistingPrptObj = { prpExist, prpCreate, sortedDataCopy }
    return filterExistingPrptObj
  }

  filterExistingPrptForBulkAssign(sortedDataCopy) {
    // let descipline = "Electricity OHLT"
    let prpCreate = []
    let prpExist = []
    let gangmembers = { ...this.state.selectedGangMembers }

    Object.keys(gangmembers).forEach(gangMem => {
      let equal = false;
      let existRole = {};
      for (let PrpTemplateIndex = 0; PrpTemplateIndex < sortedDataCopy.length; PrpTemplateIndex++) {
        const prpt = sortedDataCopy[PrpTemplateIndex];

        if (prpt.role === gangmembers[gangMem].job_title) {
          equal = true
          existRole = prpt
          break
        }
      }

      if (equal) {
        prpExist.push({ gangMem, existRole, })

      } else {

        prpCreate.push(gangMem)
      }

    }
    )
    let filterExistingPrptObj = { prpExist, prpCreate, sortedDataCopy }
    return filterExistingPrptObj
  }

  filterscheduledEmpsWithInputDate = (crmArray, fromDate, toDate) => {

    let notScheduledCRMemp = [];
    let scheduledCRMemp = [];

    crmArray.forEach(crm => {
      let scheduled = false
      if (crm.shdl_data.length > 0) {
        for (let shdlIndex = 0; shdlIndex < crm.shdl_data.length; shdlIndex++) {
          const shdl = crm.shdl_data[shdlIndex];
          if (moment(shdl.from.slice(0, 10)).isBetween(fromDate, toDate, undefined, []) && moment(shdl.to.slice(0, 10)).isBetween(fromDate, toDate, undefined, [])) {
            scheduled = true;
            break;
          }
        }
      }
      if (scheduled) { scheduledCRMemp.push(crm.emp_id) } else { notScheduledCRMemp.push(crm.emp_id) }
    })
    return scheduledCRMemp
  }

  assignEmploeesToPRP = () => {

    if (this.state.selectedCrm === "" || this.state.assignToDate === "" || this.state.assignQty === "" || this.state.assignFromDate === "") {
      this.props.enqueueSnackbar('Fill date range, crm and qty', {
        variant: 'info'
      });
      return
    }

    let selectedRowEmployeesIds = []
    for (let index = 0; index < this.state.selectedEmpRows.length; index++) {
      const element = this.state.selectedEmpRows[index];
      let id = getEmployeeID(element)
      selectedRowEmployeesIds.push(id)
    }

    Promise.all([this.getCrmSchedules(this.state.selectedCrm.id), this.getEmployeeSchedules(selectedRowEmployeesIds)])
      .then((res) => {


        // findout employees are free
        let busyEmployees = filterStandByEmps(res[1], this.state.assignFromDate, this.state.assignToDate)
        if (busyEmployees.length > 0) {
          this.props.enqueueSnackbar('Some selected employees not free on the date range', {
            variant: 'info'
          });
          return
        }

        // Then assign prpt dynamically
        let groupedByRoles = groupByField(res[0], "role_id")

        let putPRPTArray = []
        let createPRPs = {}
        let gangs = []

        for (let index = 0; index < this.state.selectedEmpRows.length; index++) {
          const element = this.state.selectedEmpRows[index];

          let prptExists = false
          for (let roleIndex = 0; roleIndex < Object.keys(groupedByRoles).length; roleIndex++) {
            const role = parseInt(Object.keys(groupedByRoles)[roleIndex]);
            if (role) {
              let curentRolePrpt = groupedByRoles[role][0].prp

              if (element.job_title === role) {
                // if already prpt exists
                putPRPTArray.push({
                  crm: this.state.selectedCrm.id,
                  prp: curentRolePrpt,
                  emp_id: getEmployeeID(element),
                  dates: this.state.assignDates,
                  descipline: 2,
                  shdl_data: [
                    {
                      to: this.state.assignToDate,
                      qty: this.state.assignQty,
                      from: this.state.assignFromDate
                    }]

                })
                prptExists = true
                break
              }
            }
          }
          if (!prptExists) {
            // if prpt not exists in the whole crm
            gangs.push({
              emp_id: getEmployeeID(element),
              role_id: element.role_id,
              dates: this.state.assignDates,
              descipline: 2,
              shdl_data: [
                {
                  to: this.state.assignToDate,
                  qty: this.state.assignQty,
                  from: this.state.assignFromDate
                }]
            })
          }


          createPRPs = { crm: this.state.selectedCrm.id, gangs }

        }


        let conditionalPromise = () => {
          if (createPRPs["gangs"].length > 0) {
            return [this.postGangsOps(createPRPs),
            this.postGangOps(putPRPTArray)]
          } else {
            return [this.postGangOps(putPRPTArray)]

          }
        }

        Promise.all(
          conditionalPromise()
        ).then(res => {
          if (this.props.refresh) {
            this.props.refresh()
          }
          // this.getPRPtemplates(this.props.project['crm']['crm'])
          // rerendering the page
          this.setState({ isLoading: false, assignSelectedOpen: false, selectedEmpRows: {}, assignToDate: '', assignFromDate: '', assignQty: '', assignDates: '', selectedCrm: "", })
          this.props.enqueueSnackbar('Selected gangmen added to PRP successfully', {
            variant: 'success'
          });
        }).catch(error => {
          console.log(error)
          this.setState({ isLoading: false, gangAdd: false })
          this.props.enqueueSnackbar('Something went wrong!', {
            variant: 'error'
          });
        })

      })

      .catch((error) => {
        console.log(error)
        this.props.enqueueSnackbar("Something failed!", {
          variant: "error",
        });
      })
  }

  assignSelected = (selectedEmpRowsObj) => {
    // let selectedempIds = Object.keys(selectedEmpRowsObj)
  }

  getEmployeeSchedules(gangIds) {
    return new Promise((resolve, reject) => {
      Service.get(roles_crm_emp + `?is_archive=0&emp=${gangIds.join()}`, {
        headers: {
          Authorization: "Token " + token,
        },
      })
        .then((res) => {
          resolve(res.data)
        })
        .catch(error => reject(error))
    })
  }

  getCrmSchedules(crmId) {
    return new Promise((resolve, reject) => {

      Service.get(roles_crm_emp + `?is_archive=0&crm=${crmId}`, {
        headers: {
          Authorization: "Token " + token,
        },
      })
        .then((res) => {
          resolve(res.data)
        })
        .catch(error => reject(error))
    })
  }

  removeSelectedAndUpdate = () => {
    if (Object.keys(this.state.selectedEmpRows).length === 0 || !this.state.assignToDate || !this.state.assignFromDate) {
      this.props.enqueueSnackbar('Ensure all data is Filled (Selected employees, date range)', {
        variant: 'info'
      });
      return
    }
    //   Service.get('/api/prp/role/remove_assign_selected/', {
    //     headers: {
    //         Authorization: "Token " + token,
    //     },
    //     data: {
    //         crm: this.state.removalSelectedCrmId,
    //         emp: Object.keys(this.state.selectedEmpRows),
    //         from: this.state.assignFromDate,
    //         to: this.state.assignToDate
    //     }
    // })
    //     .then((res) => {
    //     })
    //     .catch(error => console.log(error))

    let selectedRowEmployeesIds = []
    for (let index = 0; index < this.state.selectedEmpRows.length; index++) {
      const element = this.state.selectedEmpRows[index];
      if (element.hasOwnProperty('id')) {
        selectedRowEmployeesIds.push(element.id)
      }
      if (element.hasOwnProperty('employee_id')) {
        selectedRowEmployeesIds.push(element.employee_id)
      }
    }
    Service.post('/api/prp/role/remove_assign_selected/', {
      headers: {
        Authorization: "Token " + token,
      },
      data: {
        crm: this.state.selectedCrm.id,
        emp: selectedRowEmployeesIds,
        selected_from: moment(this.state.assignFromDate).format('DD/MM/YYYY'),
        selected_to: moment(this.state.assignToDate).format('DD/MM/YYYY')
      }
    })
      .then((res) => {
        this.setState({ removeSelectedOpen: false, isRemovalActive: false, selectedEmpRows: [], assignToDate: "", assignFromDate: "" }, () => {
          this.props.enqueueSnackbar("Selected Employees Removed for the date range!", {
            variant: "success",
          })
          if (this.props.refresh) {
            this.props.refresh()
          }
        })
      })
      .catch(error => console.log(error))

    // let selectedRowEmployeesIds = []
    // Object.keys(this.state.selectedEmpRows).map((key) => selectedRowEmployeesIds.push(parseInt(key)))

    // Promise.all([this.getEmployeeSchedules(selectedRowEmployeesIds)])
    //   .then((res) => {
    //     let prprIdGroupedObj = removePrprSchedulesforGivenDateRange(res[0], this.state.assignFromDate, this.state.assignToDate)

    //     prprBulkUpdate(prprIdGroupedObj)
    //       .then(res =>
    //         this.setState({ removeSelectedOpen: false, isRemovalActive: false, selectedEmpRows: [], assignToDate: "", assignFromDate: "" }, () => {
    //           this.props.enqueueSnackbar("Selected Employees Removed for the date range!", {
    //             variant: "success",
    //           })
    //           if (this.props.refresh) {
    //             this.props.refresh()
    //           }
    //         })

    //       )
    //       .catch(err => {
    //         this.props.enqueueSnackbar("Something failed!", {
    //           variant: "error",
    //         })
    //       }
    //       )
    //   })
    //   .catch((error) => {
    //     this.props.enqueueSnackbar("Something failed!", {
    //       variant: "error",
    //     });
    //   })
  }

  callMakeReport(extend, startDate, endDate) {
    // this.setState({ isLoading: true })
    this.props.callMakeReport(startDate, endDate)
    // setTimeout(() => {
    //   this.setState({ isLoading: false }, () => {
    //     if (extend) {
    //       let scroll = screenSizeCheck() ? 2.4 : 2.8
    //       this.ref.current.scrollLeft += (this.refWidth.current.clientWidth * scroll) / 3
    //     }
    //   })
    // }, 1000);
  }

  reduceDates() {

    if (this.ref.current.scrollLeft === 0) {
      // alert()
      let num = screenSizeCheck() ? 2 : 3
      this.setState({
        extend: this.state.extend - num,
        reduce: this.state.reduce + num,

      }, () => {
        // this.ref.current.scrollLeft = 0
        // this.ref.current.scrollLeft-=(this.refWidth.current.clientWidth)/3;
        const startDate = this.state.selectedStartDate ? moment(this.state.selectedStartDate).startOf('month') : moment().subtract(this.state.reduce, 'months').startOf('month');
        const endDate = this.state.selectedEndDate ? moment(this.state.selectedEndDate).endOf('month') : moment().add(this.state.extend, 'months').endOf('month');
        this.callMakeReport(false, startDate, endDate)
        this.setState({
          startDetect: false
        })
      })
    } else {
      let scroll = screenSizeCheck() ? 2 : 2.8
      this.ref.current.scrollLeft -= (this.refWidth.current.clientWidth * scroll) / 10;
    }
    // if(this.ref.current.scrollLeft === 0){
    //   this.setState({
    //     startDetect: true
    //   },() =>{
    //   })
    // }


  }

  extendDates() {

    let endDetect = screenSizeCheck() ? 3014 : 2800

    if (this.ref.current.scrollLeft >= endDetect) {
      // alert()
      let num = screenSizeCheck() ? 2 : 3
      this.setState({
        extend: this.state.extend + num,
        reduce: this.state.reduce - num,
      }, () => {
        const startDate = this.state.selectedStartDate ? moment(this.state.selectedStartDate).startOf('month') : moment().subtract(this.state.reduce, 'months').startOf('month');
        const endDate = this.state.selectedEndDate ? moment(this.state.selectedEndDate).endOf('month') : moment().add(this.state.extend, 'months').endOf('month');
        this.callMakeReport(true, startDate, endDate)
        this.setState({
          endDetect: false
        })
        // this.ref.current.scrollLeft+=(this.refWidth.current.clientWidth)/3
      })

    } else {
      let scroll = screenSizeCheck() ? 2 : 2.8
      this.ref.current.scrollLeft += (this.refWidth.current.clientWidth * scroll) / 10;

    }

    // if(this.ref.current.scrollLeft >= 2000){
    //   this.setState({
    //     endDetect: true
    //   })

  }

  onScroll = () => {

    // let scroll = this.ref.current.scrollLeft 
    // this.ref.current.scrollLeft 
    // this.setState({
    if (this.ref.current.scrollLeft === 0) {
      this.setState({
        startDetect: true
      })
    }
    if (this.ref.current.scrollLeft >= 2208) {
      this.setState({
        endDetect: true
      })
    }
    // })
    // if(this.ref.current.scrollLeft < 2208){
    //   // this.extendDates()
    // }else{

    // }
  }

  addAllocation(emp, prj) {
    if (this.props.addAllocation) {
      this.props.addAllocation(emp, prj)
    }
  }

  handleChange = (e) => {
    if (e.target.value === 'month') {
      let endDate = moment(this.state.selectedStartDate).add(3, 'years')
      this.setState({ selectedEndDate: endDate }, () => {
        this.props.handleTypeChange('month', this.state.selectedStartDate, this.state.selectedEndDate)
      })
    } else if (e.target.value === 'quarter') {
      let endDate = moment(this.state.selectedStartDate).add(5, 'years')
      this.setState({ selectedEndDate: endDate }, () => {
        this.props.handleTypeChange('quarter', this.state.selectedStartDate, this.state.selectedEndDate)
      })
    } else {
      let endDate = moment(this.state.selectedStartDate).add(9, 'months')
      this.setState({ selectedEndDate: endDate }, () => {
        this.props.handleTypeChange('week', this.state.selectedStartDate, this.state.selectedEndDate)
      })
    }

  }
  decreaseZoom = () => {
    let zoom = (Number(this.state.zoom) - 0.1).toFixed(1);
    if (zoom < 0.5) return;
    this.setState({ zoom: zoom })
  }

  increaseZoom = () => {
    let zoom = (Number(this.state.zoom) + 0.1).toFixed(1);
    if (zoom > 1.5) return;
    this.setState({ zoom: zoom })
  }

  prev6months = () => {
    this.setState({ selectedStartDate: moment(this.state.selectedStartDate).subtract(6, 'months'), selectedEndDate: moment(this.state.selectedEndDate).subtract(6, 'months') }, () => {
      this.callMakeReport(false, this.state.selectedStartDate, this.state.selectedEndDate)
    })
  }

  next6months = () => {
    this.setState({ selectedStartDate: moment(this.state.selectedStartDate).add(6, 'months'), selectedEndDate: moment(this.state.selectedEndDate).add(6, 'months') }, () => {
      this.callMakeReport(false, this.state.selectedStartDate, this.state.selectedEndDate)
    })
  }

  render() {

    const classes = this.props.classes;

    return (
      < div >
        {
          this.state.isLoading ? <RotateCircleLoading /> :
            this.state.allEmp?.length > 0 ?
              // <Paper className={classes.root}>
              <>
                <Box display='flex' flexDirection={'row'} mb={1} alignItems={'center'}>
                  <Box flexGrow={1} display='flex' flexDirection={'row'} alignItems={'center'}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        variant="dialog"
                        openTo="month"
                        views={["month", "year"]}
                        label="Start Month"
                        maxDate={this.state.selectedEndDate ? moment(this.state.selectedEndDate).subtract(1, 'month') : undefined}
                        autoOk={true}
                        value={this.state.selectedStartDate}
                        onChange={this.handleDateChange1}
                        style={{ marginRight: '10px' }}
                      />
                      <DatePicker
                        variant="dialog"
                        openTo="month"
                        views={["month", "year"]}
                        label="End Month"
                        minDate={this.state.selectedStartDate ? moment(this.state.selectedStartDate).add(1, 'month') : undefined}
                        autoOk={true}
                        value={this.state.selectedEndDate}
                        onChange={this.handleDateChange2}
                        style={{ marginRight: '10px' }}
                      />
                    </MuiPickersUtilsProvider>
                    <Button variant="outlined" color="primary"
                      onClick={() => {
                        this.callMakeReport(false, this.state.selectedStartDate, this.state.selectedEndDate)
                      }}> Get Report</Button>

                  </Box>

                  <Box display='flex' flexDirection={'row'} alignItems={'center'}>
                    <TextField
                      select
                      size='small'
                      variant='outlined'
                      value={this.props.type}
                      onChange={(e) => this.handleChange(e)}
                      SelectProps={{
                        native: true,
                      }}>
                      {['week', 'month', 'quarter'].map((option) => (
                        <option key={option} value={option}>
                          {option[0].toUpperCase() + option.slice(1)}
                        </option>
                      ))}
                    </TextField>

                    <Button variant="outlined" color="primary" style={{ marginLeft: "10px" }}
                      onClick={() => this.setState({ assignSelectedOpen: true })}
                    >Assign Selected</Button>

                    <Button variant="outlined" color="primary" style={{ marginLeft: "10px" }}
                      onClick={() => this.setState({ removeSelectedOpen: true })}
                    >Remove Selected</Button>

                    <Button
                      component="label"
                      variant="outlined"
                      tabIndex={-1}
                      color="primary"
                      startIcon={<ChevronLeftIcon />}
                      onClick={() => this.prev6months()}
                      style={{ marginLeft: "10px" }}>
                      prev 6 months
                    </Button>
                    <Button
                      component="label"
                      variant="outlined"
                      tabIndex={-1}
                      color="primary"
                      endIcon={<ChevronRightIcon />}
                      onClick={() => { this.next6months() }}
                      style={{ marginLeft: "10px" }}>
                      next 6 months
                    </Button>

                    <ToggleButtonGroup
                      exclusive
                      aria-label="text alignment"
                      style={{ marginLeft: "10px", float: 'right' }}
                      size='small'
                      color='primary'>
                      <ToggleButton value="weeks" aria-label="by week" onClick={this.decreaseZoom} >
                        <ZoomOut />
                      </ToggleButton>
                      <ToggleButton value="months" aria-label="by month" onClick={this.increaseZoom}>
                        <ZoomIn />
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>

                </Box>

                <div style={{ maxHeight: window.innerHeight - 210, overflowY: 'scroll' }}>
                  <div style={{ zoom: this.state.zoom }}>
                    <Table className={classes.table} stickyHeader style={{ tableLayout: 'fixed' }}  >
                      <TableHead >
                        <TableRow >
                          <TableCell
                            style={{ zIndex: 999, background: "#F8F8F8", position: "sticky" }}
                            colSpan={4}
                            width={400}>
                            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                              <Checkbox checked={this.state.selectedEmpRows.length === this.state.allEmp.length ? true : false} onChange={(e) => this.handleAllRowSelected(e.target.checked)} size="small"></Checkbox>
                              <TextField
                                onChange={(e) => this.filterByName(e)}
                                id="standard-basic"
                                label="Search by Employee"
                                type="text"
                                variant="standard"
                                value={this.state.employeeSearchField}
                                style={{
                                  width: '150px',
                                }}
                              ></TextField>
                              {this.state.employeeSearchField ? (
                                <IconButton
                                  size='small'
                                  aria-label="close"
                                  onClick={() =>
                                    this.setState({
                                      employeeSearchField: "",
                                      allEmp: this.state.allEmpCopy,
                                    })
                                  }
                                  style={{
                                    marginRight: '3px',
                                  }}>
                                  <CloseIcon fontSize='small' />
                                </IconButton>
                              ) : null}
                            </Box>
                          </TableCell>
                          {this.props.roleData.allMonths.map(month => {
                            return <TableCell key={month.month} colSpan={month.colSpan} align='center' width={month.width}>{month.month}</TableCell>
                          })}
                        </TableRow>
                        <TableRow >
                          <TableCell colSpan={2} width={100} align="center" style={{ background: "#F8F8F8", zIndex: 999 }}>
                            {"Actions & Employee Name"}
                          </TableCell>
                          <TableCell width={200} align="center" style={{ background: "#F8F8F8", position: "sticky", left: '200px', zIndex: 999 }}>
                            {"Employee No"}
                          </TableCell>
                          <TableCell width={200} align="center" style={{ background: "#F8F8F8", position: "sticky", left: '300px', zIndex: 999 }}>
                            {"Role"}
                          </TableCell>
                          {this.props.roleData.columns.map((col, colIndex) => {
                            return <TableCell key={colIndex} width={col.width} style={{ textAlign: 'center' }}>{col.title}</TableCell>
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody ref={this.refWidth}>
                        {this.state.searchLoading ?
                          <LoopCircleLoading /> :
                          this.state.allEmp.map((emp, index) =>
                            <EmployeeRow
                              work_typeObj={this.state.work_typeObj}
                              work_TypeFullObj={this.state.work_TypeFullObj}
                              ref={instance => { this.childRef = instance }}
                              refresh={this.refresh}
                              key={index}
                              selectedEmpRows={this.state.selectedEmpRows}
                              columns={this.props.roleData.columns}
                              emp={emp}
                              index={index}
                              allProjects={this.props.allProjects}
                              allRoles={this.props.allRoles}
                              othertaskObject={this.props.othertaskObject}
                              allEmployeesLeaves={this.props.allEmployeesLeaves}
                              allOtherTasks={this.props.allOtherTasks}
                              selectedStartDate={this.state.selectedStartDate}
                              selectedEndDate={this.state.selectedEndDate}
                              rolesObj={this.props.rolesObj}
                              projectObj={this.props.projectObj}
                              role={emp.role_id}
                              allEmp={this.state.allEmp}
                              updateEmployeeRow={(empRowData) => this.updateEmployeeRow(empRowData, index)}
                              updateSelected={(rowData, selectStatus) => this.updateSelected(rowData, selectStatus)}
                              addAllocation={this.addAllocation} />)}
                      </TableBody>
                    </Table>
                  </div >
                </div >
                
                <Dialog open={this.state.assignSelectedOpen} onClose={() => { this.setState({ assignSelectedOpen: false, selectedEmpRows: [] }, () => { this.handleAllRowSelected(false) }) }} fullWidth={true} maxWidth={"lg"}>
                  <DialogTitle id="form-dialog-title">
                    Assign selected employees to CRM
                    <IconButton aria-label="close" onClick={() => { this.setState({ assignSelectedOpen: false, selectedEmpRows: [] }, () => { this.handleAllRowSelected(false) }) }} style={{ position: 'absolute', top: '5px', right: '20px' }}>
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent style={{ padding: 15 }}>
                    <Grid container spacing={3}>

                      <Grid item xs={4}>
                        <Autocomplete

                          // multiple
                          id="Crm"
                          options={this.props.allProjects}
                          // disableCloseOnSelect
                          getOptionLabel={(option) => option.name}
                          onChange={(e, value) => this.setState({ selectedCrm: value })}
                          value={this.state.selectedCrm}

                          size="small"
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.name}
                            </React.Fragment>
                          )}
                          renderInput={(params) => (

                            <TextField {...params} label="Crm" placeholder="Crm" />
                          )}
                        />

                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            format="dd/MM/yyyy"
                            margin="normal"
                            id={"from"}
                            // label="From"
                            value={this.state.assignFromDate ? this.state.assignFromDate : ""}
                            onChange={(date) =>
                              this.handleCustomFromDate(date)
                            }
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            className={this.props.classes.date}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            format="dd/MM/yyyy"
                            margin="normal"
                            id={"to"}
                            // label="To"
                            value={this.state.assignToDate ? this.state.assignToDate : ""}
                            onChange={(date) =>
                              this.handleCustomToDate(date)
                            }
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            className={this.props.classes.date}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          id={"qty"}
                          label="Qty/Week"
                          type="number"
                          fullWidth
                          margin="dense"
                          value={this.state.assignQty ? this.state.assignQty : ""}
                          onChange={(e) =>
                            this.handleCustomQty(
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                      {/* <Grid item xs={12} sm={2}>
                      <Button style={{ color: 'primary' }} onClick={() => this.props.filterscheduledEmpsWithInputDate(this.state.assignFromDate, this.state.assignToDate)}>Get Available Employees</Button>
                    </Grid> */}
                    </Grid>
                    {/* 
                  <MaterialTable
                    title="Employees"
                    columns={[
                      { title: 'Employee No', field: 'ptd_employee_data.emp_no', },
                      { title: 'First Name', field: 'ptd_employee_data.first_name', },
                      { title: 'Last Name', field: 'ptd_employee_data.last_name', },
                      { title: 'Role', field: 'ptd_employee_data.job_title', lookup: this.props.allActiveRolesLupObj },
                    ]}
                    data={this.props.assignEmployees}
                    isLoading={this.state.isLoading}
                    options={{
                      headerStyle: {
                        fontWeight: 'bold',
                      },
                      paging: false,
                      padding: 'dense',
                      showTitle: false,
                      searchFieldAlignment: 'left',
                      pageSize: 20,
                      selection: true
                    }}
                    onSelectionChange={(rows) => this.setState({ selectedGangMembers: rows })}
                  // onRowClick={(event, rowData, togglePanel) => {
                  //     this.postOps({
                  //         ptd_employee: rowData.id,
                  //         general_foreman: this.props.gf.id,
                  //     })
                  // }}
                  /> */}
                  </DialogContent>
                  <DialogActions>
                    <Button color="secondary" onClick={() => { this.setState({ assignSelectedOpen: false, }, () => { this.handleAllRowSelected(false) }) }}>Cancel</Button>
                    <Button color="primary" onClick={() => this.assignEmploeesToPRP()}>Assign Selected gang</Button>
                  </DialogActions>
                </Dialog>
                <Dialog open={this.state.removeSelectedOpen} onClose={() => { this.setState({ removeSelectedOpen: false, selectedEmpRows: [], assignToDate: "", assignFromDate: "" }, () => { this.handleAllRowSelected(false) }) }} fullWidth={true} maxWidth={"lg"}>
                  <DialogTitle id="form-dialog-title">
                    Remove selected employees to CRM
                    <IconButton aria-label="close" onClick={() => { this.setState({ removeSelectedOpen: false, selectedEmpRows: [], assignToDate: "", assignFromDate: "" }, () => { this.handleAllRowSelected(false) }) }} style={{ position: 'absolute', top: '5px', right: '20px' }}>
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent style={{ padding: 15 }}>
                    <Grid container spacing={3}>
                      <Grid item xs={4}>
                        <Autocomplete
                          // multiple
                          id="Crm"
                          options={this.props.allProjects}
                          // disableCloseOnSelect
                          getOptionLabel={(option) => option.name}
                          onChange={(e, value) => this.setState({ selectedCrm: value })}
                          value={this.state.selectedCrm}
                          size="small"
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.name}
                            </React.Fragment>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} label="Crm" placeholder="Crm" />
                          )}
                        />

                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            format="dd/MM/yyyy"
                            margin="normal"
                            id={"from"}
                            // label="From"
                            value={this.state.assignFromDate ? this.state.assignFromDate : ""}
                            onChange={(date) =>
                              this.handleCustomFromDate(date)
                            }
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          // className={this.props.classes.date}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            format="dd/MM/yyyy"
                            margin="normal"
                            id={"to"}
                            // label="To"
                            value={this.state.assignToDate ? this.state.assignToDate : ""}
                            onChange={(date) =>
                              this.handleCustomToDate(date)
                            }
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          // className={this.props.classes.date}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button color="secondary" onClick={() => { this.setState({ removeSelectedOpen: false, selectedEmpRows: [], assignToDate: "", assignFromDate: "" }, () => { this.handleAllRowSelected(false) }) }}>Cancel</Button>
                    <Button color="primary" onClick={() => this.removeSelectedAndUpdate()}>Remove Selected gang For the date range</Button>
                  </DialogActions>
                </Dialog>
              </>
              :
              <p style={{ textAlign: "center", padding: '20px' }}>No Employee available for this role.</p>
        }
      </div >)
  }
}

export default withSnackbar(withStyles(useStyles)(RolesEmployeeLocation))