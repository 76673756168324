import React from 'react';
import moment from 'moment';
import Service from '../../../config/networkutils';
import { getCookie } from '../../../utils/CookieHelper';
import RolesEmployeeLocation from './RolesEmployeeLocation';
import { LoopCircleLoading, RotateCircleLoading } from 'react-loadingg';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Slide } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Typography from '@material-ui/core/Typography';
import { getCRMTableData, getEmployeeRegionWithHistory, getEmployeesLeaves, getOtherTasks, } from '../../../config/PromisesHandler';
import { DatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ZoomIn from '@material-ui/icons/ZoomIn';
import ZoomOut from '@material-ui/icons/ZoomOut';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import UserAllocation from '../../UserAllocation/UserAllocation';
import CloseIcon from '@material-ui/icons/Close';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Grid } from '@mui/material';
import { screenSizeCheck } from '../../Helper/CommonThings';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const months = { 0: 'January', 1: 'Febrauary', 2: 'March', 3: 'April', 4: 'May', 5: 'June', 6: 'July', 7: 'August', 8: 'September', 9: 'October', 10: 'November', 11: 'December' }
const token = getCookie("ptd_ts_token");
const user = JSON.parse(getCookie("ptd_ts_user"));
class ResourceLocation extends React.Component {
    constructor() {
        super()
        this.state = {
            allRoles: [],
            rolesObj: {},
            allEmp: [],
            allEmpRegionHistory: [],
            regionObj: {},
            isLoading: true,
            isLoading1: false,
            allProjects: [],
            responseData: [],
            projectObj: {},
            selectedRole: [],
            rolesData: [],
            searchRerenders: false,
            allSubSectors: [],
            selectedSubSector: [],
            allOwnerRegions: [],
            selectedOwnerRegion: [],
            allOwnerDeliveryUnits: [],
            selectedOwnerDelivery: [],
            showRole: false,
            showRoleIndex: null,
            selectedCrm: { name: '', id: '' },
            selectedEmployee: { first_name: 'Select Employee', last_name: '', id: '' },
            splittedRoleEmployees: [],
            selectedStartDate: moment().subtract(2, 'months').startOf('month'),
            selectedEndDate: moment().add(6, 'months').endOf('month'),
            allocationDialog: false,
            allocationDialogSize: 'xs',
            allocationData: null,
            toggleView: 'role',
            addEmpDisabled: false,
            type: 'week',
            zoom: 1,
        }
        this.handleRefresh = this.handleRefresh.bind(this);
        this.getDialogSize = this.getDialogSize.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.addAllocation = this.addAllocation.bind(this);
    }

    editedScheduleUIUpdate = (empArray) => {
        // Filter the response data based on the employee
        let allRoleEmployeess = [...this.state.responseData];
        for (let crmIndex = 0; crmIndex < allRoleEmployeess.length; crmIndex++) {
            for (let empIndex = 0; empIndex < empArray.length; empIndex++) {
                if ((allRoleEmployeess[crmIndex].crm === empArray[empIndex].crm) && (allRoleEmployeess[crmIndex].emp_id === empArray[empIndex].emp_id)) {
                    allRoleEmployeess[crmIndex] = empArray[empIndex];
                }
            }
        }
        this.setState({
            ...this.state,
            allRoleEmployees: allRoleEmployeess,
        });
    };

    getProjectsEmployees() {
        this.setState({ isLoading: true, isLoading1: true, addEmpDisabled: true, })

        let roles_crm_emp_id = ''

        if (this.state.type === 'week') {
            roles_crm_emp_id = '/api/prp/rolenew/get_staff_location_data/?archive_reason=0&'
        } else if (this.state.type === 'month') {
            roles_crm_emp_id = '/api/prp/rolenew/get_staff_location_data/?archive_reason=0&change_report_type=month&'
        } else if (this.state.type === 'quarter') {
            roles_crm_emp_id = '/api/prp/rolenew/get_staff_location_data/?archive_reason=0&change_report_type=quarter&'
        }

        if (this.state.selectedCrm?.id) {
            roles_crm_emp_id = roles_crm_emp_id + `crm=${this.state.selectedCrm.id}&`
        }
        if (this.state.selectedEmployee?.id) {
            roles_crm_emp_id = roles_crm_emp_id + `emp=${this.state.selectedEmployee.id}`
        }

        if (this.state.selectedStartDate && this.state.selectedEndDate) {
            roles_crm_emp_id = roles_crm_emp_id + `&from_date=${moment(this.state.selectedStartDate).format('YYYY-MM-DD')}&to_date=${moment(this.state.selectedEndDate).format('YYYY-MM-DD')}`
        }

        Service.get(roles_crm_emp_id, {
            headers: { Authorization: "Token " + token, }
        })
            .then(res => {
                let rolesss;

                if (this.state.selectedRole.length === 0) {
                    rolesss = [...this.state.allRoles]
                } else {
                    rolesss = [...this.state.selectedRole]
                }
                this.setState({
                    reportedRoles: rolesss,
                    responseData: res.data.data,
                    // isLoading: false,
                    // isLoading1: false,
                    addEmpDisabled: false,
                }, () => this.makeReport(res.data.data, res.data.all_dates, this.state.type))
            }).catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' })
                this.setState({ isLoading: false, isLoading1: false })
            })
    }

    getDropdownData() {

        return new Promise((resolve, reject) => {
            Service.get('/api/roles/get_default_data/', {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    resolve(res.data)
                })
                .catch(error => reject(error))
        })

    }

    componentDidMount() {
        Promise.all([
            this.getDropdownData(),
            getEmployeeRegionWithHistory(),
            getCRMTableData(),
            getOtherTasks(),
            getEmployeesLeaves(),
        ])
            .then(res => {
                let data = res[0].roles

                let rolesobj = {}
                data.forEach(ele => {
                    let newData = { [ele.id]: ele.job_roles }
                    rolesobj = { ...rolesobj, ...newData }
                })

                let regionObj = {}
                res[0].owner_region_sector.forEach(ele => {
                    let newData = { [ele.id]: ele.name }
                    regionObj = { ...regionObj, ...newData }
                })

                let projectObj = {}
                res[0].crm.forEach(ele => {
                    let newData = { [ele.id]: { project_code: ele.project_code, name: ele.name } }
                    projectObj = { ...projectObj, ...newData }
                })

                let othertaskObject = {}
                res[3].forEach(ele => {
                    let newData = { [ele.id]: ele.name }
                    othertaskObject = { ...othertaskObject, ...newData }
                })

                this.setState({
                    rolesObj: rolesobj,
                    allRoles: res[0].roles,
                    allEmp: res[0].employees,
                    allEmpRegionHistory: res[1],
                    allOwnerRegions: res[0].owner_region_sector,
                    regionObj: regionObj,
                    allProjects: res[0].crm,
                    projectObj: projectObj,
                    isLoading: false,
                    completedOppertunities: res[2],
                    allSubSectors: res[0].sub_sector,
                    allOwnerDeliveryUnits: res[0].owner_delivery,
                    // allMarketSubVerticles:3,
                    // rolesData:res[0]
                    allOtherTasks: res[3],
                    allEmployeesLeaves: res[4],
                    othertaskObject,
                    liveOpportunities: res[0].crm,
                }, () => {

                    let searchParams = new URLSearchParams(window.location.search)
                    let selectedRoles = []
                    let selectedRegions = []
                    let selectedSubSectors = []
                    let selectedOwnerDeliverys = []
                    let selectedCrm = []
                    let selectedEmployee = []

                    if (searchParams.get("role")) {
                        let idNum = searchParams.get("role").split(',')
                        for (let id = 0; id < idNum.length; id++) {
                            let perRole = this.state.allRoles.filter((rol) => rol.id === parseInt(idNum[id]))
                            selectedRoles.push(perRole[0])
                        }
                    }
                    if (searchParams.get("selectedOwnerRegion")) {
                        let idNum = searchParams.get("selectedOwnerRegion").split(',')
                        for (let id = 0; id < idNum.length; id++) {
                            let perRegion = this.state.allOwnerRegions.filter((regio) => regio.id === parseInt(idNum[id]))
                            selectedRegions.push(perRegion[0])
                        }
                    }
                    if (searchParams.get("selectedSubSector")) {
                        let idNum = searchParams.get("selectedSubSector").split(',')
                        for (let id = 0; id < idNum.length; id++) {
                            let perSector = this.state.allSubSectors.filter((secto) => secto.id === parseInt(idNum[id]))
                            selectedSubSectors.push(perSector[0])
                        }
                    }
                    if (searchParams.get("selectedOwnerDelivery")) {
                        let idNum = searchParams.get("selectedOwnerDelivery").split(',')
                        for (let id = 0; id < idNum.length; id++) {
                            let perODU = this.state.allOwnerDeliveryUnits.filter(odu => odu.id === parseInt(idNum[id]))
                            selectedOwnerDeliverys.push(perODU[0])
                        }
                    }
                    if (searchParams.get("employee")) {
                        let idNum = searchParams.get("employee")
                        selectedEmployee = this.state.allEmp.filter(emp => emp.id === parseInt(idNum))[0]
                    } else {
                        selectedEmployee = { first_name: 'Select Employee', last_name: '', id: '' }
                    }
                    if (searchParams.get("crm")) {
                        let idNum = searchParams.get("crm")
                        selectedCrm = this.state.allProjects.filter(crm => crm.id === parseInt(idNum))[0]
                    }
                    if (selectedCrm.name || selectedEmployee.id) {
                        this.setState({
                            selectedRole: selectedRoles, selectedCrm, selectedEmployee, isLoading: true, toggleView: 'crm'
                        }, () => this.getProjectsEmployees())
                    } else if (selectedRoles.length > 0 ||
                        selectedRegions.length > 0 ||
                        selectedSubSectors.length > 0 ||
                        selectedOwnerDeliverys.length > 0) {
                        this.setState({
                            selectedRole: selectedRoles, selectedOwnerRegion: selectedRegions
                            , selectedSubSector: selectedSubSectors, selectedOwnerDelivery: selectedOwnerDeliverys, toggleView: 'role'
                        }, () => this.fetchOps())
                    }
                })
            })
            .catch(e => {
                this.props.enqueueSnackbar('Something went wrong. Please try again later.', {
                    variant: 'error'
                });
            })

    }

    handleRefresh() {
        this.componentDidMount()
    }

    makeReport(allRoles, datesArray, type) {

        let reportedRoles = []
        let templateRoles = []
        let splittedRoleEmployees = []
        let finalColumns = []
        let allMonths = []

        for (let index = 0; index < allRoles.length; index++) {
            const element = allRoles[index];

            const startOfMonth = this.state.selectedStartDate ? moment(this.state.selectedStartDate).startOf('month') : moment().subtract(2, 'months').startOf('month');
            const endOfMonth = this.state.selectedEndDate ? moment(this.state.selectedEndDate).endOf('month') : moment().add(6, 'months').endOf('month');

            let startDate = moment(startOfMonth)
            let endDate = moment(endOfMonth)

            let result = [];

            if (endDate.isBefore(startDate)) {
                throw new Error("End date must be greater than start date.")
            }

            while (startDate.isBefore(endDate)) {
                result.push(months[startDate.month()] + ' ' + startDate.year());
                startDate.add(1, 'month');
            }

            // let allWeekDates = weekStartDates(startOfMonth, endOfMonth);

            let final = []
            let colspan = 1;
            if (type === 'week') {
                for (let i = 0; i < datesArray.length; i++) {
                    // debugger
                    let date = moment(datesArray[i], 'DD/MM/YYYY')
                    let monthYear = months[date.month()] + ' ' + date.year()
                    if (!result.length) {
                        result.push(monthYear)
                        final.push({ month: monthYear, colSpan: colspan })
                    }
                    else {
                        if (result[result.length - 1] === monthYear) {
                            colspan += 1
                            final[final.length - 1]['colSpan'] = colspan
                        }
                        else {
                            colspan = 1
                            result.push(monthYear)
                            final.push({ month: monthYear, colSpan: colspan })
                        }
                    }

                }
            } else if (type === 'month') {
                for (let i = 0; i < datesArray.length; i++) {
                    let date = moment(datesArray[i])
                    if (final.map((ele) => ele.month).indexOf(date.year()) === -1) {
                        final.push({ month: date.year(), colSpan: 1 })
                    } else {
                        final[final.map((ele) => ele.month).indexOf(date.year())]['colSpan'] += 1
                    }

                }

            } else {
                for (let i = 0; i < datesArray.length; i++) {
                    let year = datesArray[i].split('-')[1]
                    if (final.map((ele) => ele.month).indexOf(year) === -1) {
                        final.push({ month: year, colSpan: 1 })
                    } else {
                        final[final.map((ele) => ele.month).indexOf(year)]['colSpan'] += 1
                    }

                }
            }
            for (let index = 0; index < final.length; index++) {
                const element = final[index];
                element.width = 150 * element.colSpan
            }

            allMonths = final

            reportedRoles.push(this.state.reportedRoles.filter((ele) => ele.id === element.role_id)[0])

            let weeks = [];
            let weekdata = [];
            for (let i = 0; i < element.project_details.length; i++) {
                let roleItem = element.project_details[i];
                for (let m = 0; m < datesArray.length; m++) {
                    let date = datesArray[m]
                    if (roleItem.dates.hasOwnProperty(date) && roleItem.dates[date] !== 0) {
                        if (weeks.indexOf(date) === -1) {
                            weeks.push(date);
                            weekdata.push({ name: [roleItem.crm_name], project_code: [[roleItem.crm_project_code]], crmId: [roleItem.crm], crm_color_code: [roleItem.crm_color_code], })
                        } else {
                            if (weekdata[weeks.indexOf(date)]['name'] !== "") {
                                weekdata[weeks.indexOf(date)]['name'].push(roleItem.crm_name)
                                weekdata[weeks.indexOf(date)]['project_code'].push(roleItem.crm_project_code)
                                weekdata[weeks.indexOf(date)]['crmId'].push(roleItem.crm)
                                weekdata[weeks.indexOf(date)]['crm_color_code'].push(roleItem.crm_color_code)
                            } else if (weekdata[weeks.indexOf(date)]['name'] === "") {
                                weekdata[weeks.indexOf(date)]['name'].push(this.state.projectObj[roleItem.crm_name])
                                weekdata[weeks.indexOf(date)]['project_code'].push(roleItem.crm_project_code)
                                weekdata[weeks.indexOf(date)]['crmId'].push(roleItem.crm)
                                weekdata[weeks.indexOf(date)]['crm_color_code'].push(roleItem.crm_color_code)
                            }
                        }
                    }
                    else {
                        if (weeks.indexOf(date) === -1) {
                            weeks.push(date);
                            weekdata.push({ name: [], crmId: [], project_code: [], crm_color_code: [] })
                        }
                    }
                }
            }
            element['weeks'] = weeks;
            element['weekdata'] = weekdata
            if (element.weekdata.length > 0) {
                if ((!element.end_date ? true : moment(element.end_date).isAfter(moment().format()))) {
                    // filterEmp.push(element)
                }
            }

            let columns = []
            for (let i = 0; i < datesArray.length; i++) {
                let newObj = {
                    title: type === "week" ? moment(datesArray[i], 'DD/MM/YYYY').format('DD MMM') : type === "month" ? moment(datesArray[i]).format('MMM YYYY') : type === "quarter" ? datesArray[i] : '',
                    field: datesArray[i],
                    type: 'number',
                    width: 150,
                    sorting: false,
                    editable: 'never',
                    filtering: false
                }
                columns.push(newObj)
            }
            finalColumns = columns

        }
        // allRoles.sort((a, b) => b.role_name - a.role_name);
        splittedRoleEmployees.push({ role: 0, allEmpCopy: allRoles, allEmp: allRoles, columns: finalColumns, allMonths: allMonths })
        this.setState({ splittedRoleEmployees, reportedRoles, templateRoles, isLoading: false, isLoading1: false, }, () => {
            console.log(this.state.splittedRoleEmployees)
        })
    }

    fetchOps() {
        this.setState({ isLoading1: true, addEmpDisabled: true })

        let selectedRolesIds = [];
        this.state.selectedRole.forEach((role) => {
            selectedRolesIds.push(role.id);
        });

        let selectedSubSectorsIds = [];
        this.state.selectedSubSector.forEach((sector) => {
            selectedSubSectorsIds.push(sector.id);
        });

        let selectedOwnerRegionIds = [];
        this.state.selectedOwnerRegion.forEach((region) => {
            selectedOwnerRegionIds.push(region.id);
        });

        let selectedOwnerDeliveryIds = [];
        this.state.selectedOwnerDelivery.forEach((odu) => {
            selectedOwnerDeliveryIds.push(odu.id);
        });
        let roles_sectors_regions_OD_uri = '';
        if (this.state.type === 'week') {
            roles_sectors_regions_OD_uri = '/api/prp/rolenew/get_staff_location_data/?archive_reason=0&'
        } else if (this.state.type === 'month') {
            roles_sectors_regions_OD_uri = '/api/prp/rolenew/get_staff_location_data/?archive_reason=0&change_report_type=month&'
        } else if (this.state.type === 'quarter') {
            roles_sectors_regions_OD_uri = '/api/prp/rolenew/get_staff_location_data/?archive_reason=0&change_report_type=quarter&'
        }
        if (selectedRolesIds.length > 0) {
            roles_sectors_regions_OD_uri = roles_sectors_regions_OD_uri + `role=${selectedRolesIds.join()}&`
        }
        if (selectedSubSectorsIds.length > 0) {
            roles_sectors_regions_OD_uri = roles_sectors_regions_OD_uri + `sector=${selectedSubSectorsIds.join()}&`
        }
        if (selectedOwnerRegionIds.length > 0) {
            roles_sectors_regions_OD_uri = roles_sectors_regions_OD_uri + `region=${selectedOwnerRegionIds.join()}&`
        }
        if (selectedOwnerDeliveryIds.length > 0) {
            roles_sectors_regions_OD_uri = roles_sectors_regions_OD_uri + `delivery=${selectedOwnerDeliveryIds.join()}&`
        }
        if (this.state.selectedEmployee?.id) {
            roles_sectors_regions_OD_uri = roles_sectors_regions_OD_uri + `emp=${this.state.selectedEmployee.id}`
        }
        if (roles_sectors_regions_OD_uri[roles_sectors_regions_OD_uri.length - 1] === "&") {
            roles_sectors_regions_OD_uri = roles_sectors_regions_OD_uri.substring(0, roles_sectors_regions_OD_uri.length - 1)
        }

        if (this.state.selectedStartDate && this.state.selectedEndDate) {
            roles_sectors_regions_OD_uri = roles_sectors_regions_OD_uri + `&from_date=${moment(this.state.selectedStartDate).format('YYYY-MM-DD')}&to_date=${moment(this.state.selectedEndDate).format('YYYY-MM-DD')}`
        }

        Service.get(roles_sectors_regions_OD_uri, {
            headers: {
                Authorization: "Token " + token,
            }
        })
            .then(res => {
                let rolesss;
                if (this.state.selectedRole.length === 0) {
                    rolesss = [...this.state.allRoles]
                } else {
                    rolesss = [...this.state.selectedRole]
                }
                this.setState({
                    reportedRoles: rolesss,
                    addEmpDisabled: false,
                    responseData: res.data.data,
                    // isLoading: false,
                    // isLoading1: false,
                }, () => this.makeReport(res.data.data, res.data.all_dates, this.state.type))
            })
            .catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
                this.setState({ isLoading: false, isLoading1: false })
            })
    }

    exportExcel(filter) {
        let roles_sectors_regions_OD_uri = "/api/prp/rolenew/get_staff_location_data/?archive_reason=0&get_report=true&";
        if (filter === 'crm') {
            if (this.state.selectedEmployee) {
                if (this.state.selectedEmployee.first_name !== 'Select Employee') {
                    roles_sectors_regions_OD_uri = roles_sectors_regions_OD_uri + `emp=${this.state.selectedEmployee.id}&`
                }
            }
            if (this.state.selectedCrm) {
                if (this.state.selectedCrm.id) {
                    roles_sectors_regions_OD_uri = roles_sectors_regions_OD_uri + `crm=${this.state.selectedCrm.id}&`
                }
            }

        } else {
            let selectedRolesIds = [];
            this.state.selectedRole.forEach((role) => {
                selectedRolesIds.push(role.id);
            });

            let selectedSubSectorsIds = [];
            this.state.selectedSubSector.forEach((sector) => {
                selectedSubSectorsIds.push(sector.id);
            });

            let selectedOwnerRegionIds = [];
            this.state.selectedOwnerRegion.forEach((region) => {
                selectedOwnerRegionIds.push(region.id);
            });

            let selectedOwnerDeliveryIds = [];
            this.state.selectedOwnerDelivery.forEach((odu) => {
                selectedOwnerDeliveryIds.push(odu.id);
            });
            if (selectedRolesIds.length > 0) {
                roles_sectors_regions_OD_uri = roles_sectors_regions_OD_uri + `role=${selectedRolesIds.join()}&`
            }
            if (selectedSubSectorsIds.length > 0) {
                roles_sectors_regions_OD_uri = roles_sectors_regions_OD_uri + `sector=${selectedSubSectorsIds.join()}&`
            }
            if (selectedOwnerRegionIds.length > 0) {
                roles_sectors_regions_OD_uri = roles_sectors_regions_OD_uri + `region=${selectedOwnerRegionIds.join()}&`
            }
            if (selectedOwnerDeliveryIds.length > 0) {
                roles_sectors_regions_OD_uri = roles_sectors_regions_OD_uri + `delivery=${selectedOwnerDeliveryIds.join()}&`
            }
        }


        if (roles_sectors_regions_OD_uri[roles_sectors_regions_OD_uri.length - 1] === "&") {
            roles_sectors_regions_OD_uri = roles_sectors_regions_OD_uri.substring(0, roles_sectors_regions_OD_uri.length - 1)
        }

        if (this.state.selectedStartDate && this.state.selectedEndDate) {
            roles_sectors_regions_OD_uri = roles_sectors_regions_OD_uri + `&from_date=${moment(this.state.selectedStartDate).format('YYYY-MM-DD')}&to_date=${moment(this.state.selectedEndDate).format('YYYY-MM-DD')}`
        }

        Service.get(roles_sectors_regions_OD_uri, {
            headers: {
                Authorization: "Token " + token,
            }
        })
            .then(res => {
                this.setState({
                    excelExportDialog: true
                })
            })
            .catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
                this.setState({ isLoading: false, isLoading1: false })
            })
    }

    filterCRM() {
        if (this.state.selectedRole.length > 0 || this.state.selectedOwnerRegion.length > 0 || this.state.selectedSubSector.length > 0 || this.state.selectedOwnerDelivery.length > 0) {
            this.fetchOps()
        } else {
            this.props.enqueueSnackbar('Please select atleast one of the filters!', { variant: 'warning' })
        }
    }

    reRenderingEmpLocation() {
        this.setState({ searchRerenders: true });
    }

    handleOwnerDeliveryChange = (e, value) => {
        let searchParams = new URLSearchParams(window.location.search)
        if (value.length > 0) {
            let selectedOwnerDeliveryIds = [];
            value.forEach((od) => {
                selectedOwnerDeliveryIds.push(od.id)
            })
            if (searchParams.has("selectedOwnerDelivery")) {
                searchParams.set("selectedOwnerDelivery", selectedOwnerDeliveryIds.join(","))
            } else {
                searchParams.append("selectedOwnerDelivery", selectedOwnerDeliveryIds.join(","))
            }

        } else if (value.length === 0) {
            searchParams.delete("selectedOwnerDelivery")

        }
        // sessionStorage.setItem("lasturi", url + '?' + searchParams.toString())
        // window.history.pushState({ id: window.location.href }, '', url + '?' + searchParams.toString())
        let hash = window.location.hash
        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

        window.history.pushState(null, '', newRelativePathQuery);

        this.setState({ selectedOwnerDelivery: value })
    }

    handleSubSectorChange = (e, value) => {
        let searchParams = new URLSearchParams(window.location.search)
        if (value.length > 0) {
            let selectedSubSectorIds = [];
            value.forEach((subSector) => {
                selectedSubSectorIds.push(subSector.id)
            })
            if (searchParams.has("selectedSubSector")) {
                searchParams.set("selectedSubSector", selectedSubSectorIds.join(","))
            } else {
                searchParams.append("selectedSubSector", selectedSubSectorIds.join(","))
            }

        } else if (value.length === 0) {
            searchParams.delete("selectedSubSector")
        }
        // sessionStorage.setItem("lasturi", url + '?' + searchParams.toString())
        // window.history.pushState({ id: window.location.href }, '', url + '?' + searchParams.toString())
        let hash = window.location.hash
        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

        window.history.pushState(null, '', newRelativePathQuery);


        this.setState({ selectedSubSector: value })
    }

    handleRoleChange = (e, value) => {
        let searchParams = new URLSearchParams(window.location.search)
        if (value.length > 0) {
            let selectedRolesIds = [];
            value.forEach((region) => {
                selectedRolesIds.push(region.id)
            })
            if (searchParams.has("role")) {
                searchParams.set("role", selectedRolesIds.join(","))
            } else {
                searchParams.append("role", selectedRolesIds.join(","))
            }

        } else if (value.length === 0) {
            searchParams.delete("role")

        }
        // sessionStorage.setItem("lasturi", url + '?' + searchParams.toString())
        // window.history.pushState({ id: window.location.href }, '', url + '?' + searchParams.toString())
        let hash = window.location.hash
        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

        window.history.pushState(null, '', newRelativePathQuery);

        this.setState({ selectedRole: value, reportedRoles: value })
    }

    handleCrmChange(e, value) {
        let searchParams = new URLSearchParams(window.location.search)
        if (value) {
            // let selectedCrmIds = [];
            // value.forEach((crm) => {
            //     selectedCrmIds.push(crm.id)
            // })
            if (searchParams.has("crm")) {
                searchParams.set("crm", value.id)
            } else {
                searchParams.append("crm", value.id)
            }

        } else {
            searchParams.delete("crm")
        }

        // sessionStorage.setItem("lasturi", url + '?' + searchParams.toString())
        // window.history.pushState({ id: window.location.href }, '', url + '?' + searchParams.toString())
        let hash = window.location.hash
        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

        window.history.pushState(null, '', newRelativePathQuery);

        this.setState({ selectedCrm: value })
    }

    handleEmployeeChange(e, value) {
        let searchParams = new URLSearchParams(window.location.search)
        if (value) {
            // let selectedCrmIds = [];
            // value.forEach((crm) => {
            //     selectedCrmIds.push(crm.id)
            // })
            if (searchParams.has("employee")) {
                searchParams.set("employee", value.id)
            } else {
                searchParams.append("employee", value.id)
            }

        } else {

            searchParams.delete("employee")
        }

        // sessionStorage.setItem("lasturi", url + '?' + searchParams.toString())
        // window.history.pushState({ id: window.location.href }, '', url + '?' + searchParams.toString())
        let hash = window.location.hash
        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

        window.history.pushState(null, '', newRelativePathQuery);

        this.setState({ selectedEmployee: value })

    }

    handleRegionChange = (e, value) => {
        let searchParams = new URLSearchParams(window.location.search)
        if (value.length > 0) {
            let selectedRegionIds = [];
            value.forEach((region) => {
                selectedRegionIds.push(region.id)
            })
            if (searchParams.has("selectedOwnerRegion")) {
                searchParams.set("selectedOwnerRegion", selectedRegionIds.join(","))
            } else {
                searchParams.append("selectedOwnerRegion", selectedRegionIds.join(","))
            }

        } else if (value.length === 0) {
            searchParams.delete("selectedOwnerRegion")

        }

        // sessionStorage.setItem("lasturi", url + '?' + searchParams.toString())
        // window.history.pushState({ id: window.location.href }, '', url + '?' + searchParams.toString())

        let hash = window.location.hash
        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

        window.history.pushState(null, '', newRelativePathQuery);


        this.setState({ selectedOwnerRegion: value })
    }

    openRole = (roleIndex) => { this.setState({ showRole: (!this.state.showRole), showRoleIndex: roleIndex }) }

    getReport() {
        this.setState({ isLoading1: true }, () => {
            this.setState({ isLoading1: false }, () => {
                if (this.state.selectedCrm?.id || this.state.selectedEmployee?.id || this.state.selectedRole?.length > 0) {
                    this.setState({ isLoading: true, splittedRoleEmployees: [] }, () => {
                        this.getProjectsEmployees()
                    })
                } else if (this.state.selectedRole.length > 0 || this.state.selectedOwnerRegion.length > 0 || this.state.selectedSubSector.length > 0 || this.state.selectedOwnerDelivery.length > 0) {
                    this.filterCRM()
                } else {
                    this.fetchOps()
                }
            })
        })
    }

    handleDateChange1 = (d) => {
        this.setState({ selectedStartDate: d })
    }

    handleDateChange2 = (d) => {
        this.setState({ selectedEndDate: d })
    }

    prev6months = () => {
        if (!this.state.selectedStartDate || !this.state.selectedEndDate) {
            this.setState({ selectedStartDate: moment().subtract(2, 'months').startOf('month'), selectedEndDate: moment().add(6, 'months').endOf('month') }, () => {
                this.setState({ selectedStartDate: moment(this.state.selectedStartDate).subtract(6, 'months'), selectedEndDate: moment(this.state.selectedEndDate).subtract(6, 'months') }, () => {
                    this.getReports()
                })
            })
        } else {
            this.setState({ selectedStartDate: moment(this.state.selectedStartDate).subtract(6, 'months'), selectedEndDate: moment(this.state.selectedEndDate).subtract(6, 'months') }, () => {
                this.getReports()
            })
        }

    }

    next6months = () => {
        if (!this.state.selectedStartDate || !this.state.selectedEndDate) {
            this.setState({ selectedStartDate: moment().subtract(2, 'months').startOf('month'), selectedEndDate: moment().add(6, 'months').endOf('month') }, () => {
                this.setState({ selectedStartDate: moment(this.state.selectedStartDate).add(6, 'months'), selectedEndDate: moment(this.state.selectedEndDate).add(6, 'months') }, () => {
                    this.getReports(this.state.selectedStartDate, this.state.selectedEndDate)
                })
            })
        } else {
            this.setState({ selectedStartDate: moment(this.state.selectedStartDate).add(6, 'months'), selectedEndDate: moment(this.state.selectedEndDate).add(6, 'months') }, () => {
                this.getReports(this.state.selectedStartDate, this.state.selectedEndDate)
            })
        }
    }

    getReports() {
        if (!this.state.selectedEndDate || !this.state.selectedStartDate) {
            this.props.enqueueSnackbar("Please select the months!", {
                variant: "warning",
            });
        } else {
            this.getReport()
        }
    }

    getDialogSize = (size) => {
        this.setState({ allocationDialogSize: size })
    }

    closeDialog = () => {
        this.setState({ allocationDialog: false, allocationDialogSize: "xs", allocationData: null }, () => {
            if (this.state.selectedCrm?.id || this.state.selectedEmployee?.id || this.state.selectedRole?.length > 0) {
                this.setState({ isLoading: true, splittedRoleEmployees: [] }, () => {
                    this.getProjectsEmployees()
                })
            } else if (this.state.selectedRole.length > 0 || this.state.selectedOwnerRegion.length > 0 || this.state.selectedSubSector.length > 0 || this.state.selectedOwnerDelivery.length > 0) {
                this.filterCRM()
            } else {
                this.fetchOps()
            }
        })
    }

    addAllocation = (emp, prj) => {
        let allocationData = { emp: emp, prj: prj }
        this.setState({ allocationData: allocationData }, () => {
            this.setState({ allocationDialog: true, allocationDialogSize: "xs" })
        })
    }

    roleGetReport() {
        let searchParams = new URLSearchParams(window.location.search)
        if (searchParams.has("crm")) {
            searchParams.delete("crm")
        }
        if (searchParams.has("employee")) {
            searchParams.delete("employee")
        }
        let hash = window.location.hash
        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

        window.history.pushState(null, '', newRelativePathQuery);
        this.setState({
            selectedCrm: { name: '', id: '' },
            selectedEmployee: { first_name: 'Select Employee', last_name: '', id: '' },
            showRole: false, showRoleIndex: null, splittedRoleEmployees: []
        }, () => {
            this.filterCRM()
        })
    }

    crmGetReport() {
        let searchParams = new URLSearchParams(window.location.search)
        if (searchParams.has("selectedOwnerDelivery")) {
            searchParams.delete("selectedOwnerDelivery")
        }
        if (searchParams.has("selectedSubSector")) {
            searchParams.delete("selectedSubSector")
        }
        if (searchParams.has("role")) {
            searchParams.delete("role")
        }
        if (searchParams.has("selectedOwnerRegion")) {
            searchParams.delete("selectedOwnerRegion")
        }
        let hash = window.location.hash
        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

        window.history.pushState(null, '', newRelativePathQuery);
        if (this.state.selectedCrm?.id || this.state.selectedEmployee?.id) {
            this.setState({
                splittedRoleEmployees: [],
                selectedSubSector: [],
                selectedRole: [],
                selectedOwnerDelivery: [],
                selectedOwnerRegion: [],
            }, () => {
                this.getProjectsEmployees()
            })
        } else {
            this.props.enqueueSnackbar('Please select atleast one of the filters!', { variant: 'warning' })
        }
    }

    handleChange = (event) => {
        this.setState({ type: event.target.value }, () => {
            if (this.state.type === 'month') {
                let endDate = moment(this.state.selectedStartDate).add(3, 'years')
                this.setState({ selectedEndDate: endDate }, () => {
                    if (this.state.toggleView === 'crm') {
                        this.getProjectsEmployees()
                    } else {
                        this.fetchOps()
                    }
                })
            } else if (this.state.type === 'quarter') {
                let endDate = moment(this.state.selectedStartDate).add(5, 'years')
                this.setState({ selectedEndDate: endDate }, () => {
                    if (this.state.toggleView === 'crm') {
                        this.getProjectsEmployees()
                    } else {
                        this.fetchOps()
                    }
                })
            } else {
                let endDate = moment(this.state.selectedStartDate).add(9, 'months')
                this.setState({ selectedEndDate: endDate }, () => {
                    if (this.state.toggleView === 'crm') {
                        this.getProjectsEmployees()
                    } else {
                        this.fetchOps()
                    }
                })
            }

        });
    }

    decreaseZoom = () => {
        let zoom = (Number(this.state.zoom) - 0.1).toFixed(1);
        if (zoom < 0.5) return;
        this.setState({ zoom: zoom })
    }

    increaseZoom = () => {
        let zoom = (Number(this.state.zoom) + 0.1).toFixed(1);
        if (zoom > 1.5) return;
        this.setState({ zoom: zoom })
    }

    screenSizeCheck() {
        if (window.innerWidth > 1600) {
            return true
        } else {
            return false
        }
    }


    render() {
        // const classes = this.props.classes;

        const handleClose = () => {
            this.setState({ allocationDialog: false, allocationDialogSize: "xs", allocationData: null })
        };

        const handleToggle = (e, newValue) => {
            if (newValue !== null) {
                this.setState({ toggleView: newValue })
            }
        };

        return (
            <div>
                {this.state.isLoading ? <RotateCircleLoading color="#005D99" /> :
                    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>

                        <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} mb={'10px'} width={'100%'}>
                            <ToggleButtonGroup
                                color="primary"
                                size="small"
                                value={this.state.toggleView}
                                onChange={handleToggle}
                                exclusive
                                aria-label="text formatting"
                            >
                                <ToggleButton value={"role"} aria-label="to_lead">
                                    Sector/Region/Owner Delivery Unit/Role
                                </ToggleButton>
                                <ToggleButton value={"crm"} aria-label="to_em">
                                    CRM/Employee
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Box>

                        {this.state.toggleView === 'role' ?
                            <Grid container spacing={1} style={{ marginBottom: '20px' }}>
                                <Grid item xs={this.screenSizeCheck() ? 2.3 : 2}>
                                    <Autocomplete
                                        multiple
                                        id="sub-sector"
                                        limitTags={1}
                                        options={this.state.allSubSectors}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.name}
                                        onChange={(e, value) => this.handleSubSectorChange(e, value)}
                                        value={this.state.selectedSubSector}
                                        size="small"
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.name}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Sector" placeholder="Sector" />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={this.screenSizeCheck() ? 2.5 : 2}>
                                    <Autocomplete
                                        multiple
                                        id="owner-region-sector"
                                        limitTags={1}
                                        options={this.state.allOwnerRegions}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.name}
                                        onChange={(e, value) => this.handleRegionChange('selectedOwnerRegion', value)}
                                        value={this.state.selectedOwnerRegion}
                                        size="small"
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.name}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Region" placeholder="Region" />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={2.5}>
                                    <Autocomplete
                                        multiple
                                        id="owner-delivery-unit"
                                        limitTags={1}
                                        options={this.state.allOwnerDeliveryUnits}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.name}
                                        onChange={(e, value) => this.handleOwnerDeliveryChange(e, value)}
                                        value={this.state.selectedOwnerDelivery}
                                        size="small"
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.name}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Owner Delivery Unit" placeholder="Owner Delivery Unit" />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <Autocomplete
                                        multiple
                                        id="roles"
                                        limitTags={1}
                                        options={this.state.allRoles}
                                        getOptionLabel={(option) => option.job_roles}
                                        onChange={(e, value) => this.handleRoleChange(e, value)}
                                        disableCloseOnSelect
                                        value={this.state.selectedRole}
                                        size="small"
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.job_roles}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Role" placeholder="Role" />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={'auto'}>
                                    <Button variant="contained" color="primary" onClick={() => this.roleGetReport()}> Get Report</Button>
                                    <Button variant="contained" style={{ marginLeft: "5px" }} color="primary" onClick={() => { this.exportExcel('role') }}>
                                        Excel Export
                                    </Button>
                                </Grid>
                            </Grid> :
                            <Grid container justifyContent="center" spacing={2} style={{ marginBottom: '20px' }}>
                                <Grid item xs={3}>
                                    <Autocomplete
                                        // multiple
                                        id="Crm"
                                        options={this.state.allProjects}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.name}
                                        onChange={(e, value) => this.handleCrmChange(e, value)}
                                        value={this.state.selectedCrm}
                                        size="small"
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.name}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (

                                            <TextField {...params} variant="outlined" label="Crm" placeholder="Crm" />
                                        )}
                                    />

                                </Grid>
                                <Grid item xs={3}>
                                    <Autocomplete
                                        // multiple
                                        id="Employee"
                                        options={this.state.allEmp}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.first_name + ' ' + option.last_name}
                                        onChange={(e, value) => this.handleEmployeeChange(e, value)}
                                        value={this.state.selectedEmployee}
                                        size="small"
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.first_name + ' ' + option.last_name}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (

                                            <TextField {...params} variant="outlined" label="Employee" placeholder="Employee" />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Button variant="contained" color="primary" onClick={() => this.crmGetReport()}> Get Report</Button>
                                    <Button variant="contained" style={{ marginLeft: "5px" }} color="primary" onClick={() => { this.exportExcel('crm') }}>
                                        Excel Export
                                    </Button>
                                </Grid>
                            </Grid>}

                        {this.state.splittedRoleEmployees.length > 0 ?
                            <Box display='flex' flexDirection={'row'} mb={1}>
                                <Box flexGrow={1} display='flex' flexDirection={'row'} alignItems={'center'}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            variant="dialog"
                                            openTo="month"
                                            views={["month", "year"]}
                                            label="Start Month"
                                            maxDate={this.state.selectedEndDate ? moment(this.state.selectedEndDate).subtract(1, 'month') : undefined}
                                            autoOk={true}
                                            value={this.state.selectedStartDate}
                                            onChange={this.handleDateChange1}
                                            style={{ marginRight: '10px' }}
                                        />
                                        <DatePicker
                                            variant="dialog"
                                            openTo="month"
                                            views={["month", "year"]}
                                            label="End Month"
                                            minDate={this.state.selectedStartDate ? moment(this.state.selectedStartDate).add(1, 'month') : undefined}
                                            autoOk={true}
                                            value={this.state.selectedEndDate}
                                            onChange={this.handleDateChange2}
                                            style={{ marginRight: '10px' }}
                                        />
                                    </MuiPickersUtilsProvider>
                                    <Button variant="contained" color="primary"
                                        onClick={() => {
                                            this.getReports()
                                        }}> Get Report</Button>

                                </Box>
                                <Box display='flex' flexDirection={'row'} alignItems={'center'}>
                                    <TextField
                                        size='small'
                                        select
                                        variant='outlined'
                                        value={this.state.type}
                                        onChange={(e) => this.handleChange(e)}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        style={{ marginRight: '10px' }} >
                                        {['week', 'month', 'quarter'].map((option) => (
                                            <option key={option} value={option}>
                                                {option[0].toUpperCase() + option.slice(1)}
                                            </option>
                                        ))}
                                    </TextField>
                                    {/* <Box display={'flex'} flexDirection={'row'} margin={'dense'}> */}
                                    <Button
                                        size='small'
                                        component="label"
                                        variant="outlined"
                                        tabIndex={-1}
                                        color="primary"
                                        startIcon={<PersonAddAlt1Icon />}
                                        onClick={() => this.setState({ allocationDialog: true })}
                                        disabled={this.state.addEmpDisabled}
                                    >
                                        Add Employee
                                    </Button>
                                    <Button size='small' variant="outlined" color="primary" onClick={() => this.prev6months()} style={{ marginLeft: "10px" }}>
                                        prev 6 months
                                    </Button>
                                    <Button size='small' variant="outlined" color="primary" onClick={() => this.next6months()} style={{ marginLeft: "10px" }}>
                                        next 6 months
                                    </Button>
                                    <ToggleButtonGroup
                                        exclusive
                                        aria-label="text alignment"
                                        style={{ marginLeft: "10px", float: 'right' }}
                                        size='small'
                                        color='primary'
                                    >
                                        <ToggleButton value="weeks" aria-label="by week" onClick={this.decreaseZoom} >
                                            <ZoomOut />
                                        </ToggleButton>
                                        <ToggleButton value="months" aria-label="by month" onClick={this.increaseZoom}>
                                            <ZoomIn />
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Box>
                                {/* </Box> */}
                            </Box> : null}
                        {
                            this.state.isLoading1 ? <LoopCircleLoading color="#005D99" /> :
                                this.state.responseData.length > 0 ?
                                    <div>{this.state.splittedRoleEmployees.length > 0 ?
                                        <div style={{ maxHeight: window.innerHeight - (screenSizeCheck() ? 260 : 292), overflowY: 'scroll' }}>
                                            {this.state.splittedRoleEmployees.map((role, roleIndex) => (
                                                <div style={{ zoom: this.state.zoom }}>
                                                    <RolesEmployeeLocation
                                                        selectedStartDate={this.state.selectedStartDate}
                                                        selectedEndDate={this.state.selectedEndDate}
                                                        rolesObj={this.state.rolesObj}
                                                        refreshData={this.handleRefresh}
                                                        roleData={this.state.splittedRoleEmployees[roleIndex]}
                                                        projectObj={this.state.projectObj}
                                                        role={this.state.reportedRoles[roleIndex]}
                                                        templateRoles={this.state.responseData}
                                                        allOtherTasks={this.state.allOtherTasks}
                                                        allEmployeesLeaves={this.state.allEmployeesLeaves}
                                                        othertaskObject={this.state.othertaskObject}
                                                        allProjects={this.state.allProjects}
                                                        allRoles={this.state.allRoles}
                                                        liveOpportunities={this.state.liveOpportunities}
                                                        updateTheUpdatedSchedules={this.editedScheduleUIUpdate}
                                                        addAllocation={this.addAllocation}
                                                        type={this.state.type}
                                                    />
                                                </div>))
                                            }
                                        </div> : <Typography style={{ textAlign: 'center' }}>No report available for the selected Options</Typography>}
                                    </div>
                                    : this.state.selectedRole.length > 0 && this.state.selectedOwnerRegion.length > 0 && this.state.selectedSubSector.length > 0 && this.state.selectedOwnerDelivery.length > 0 ? (<Typography style={{ textAlign: 'center' }}>{'No employees available for the selected Filters !'}</Typography>) : null
                        }
                        <Dialog
                            fullWidth
                            maxWidth={this.state.allocationDialogSize}
                            open={this.state.allocationDialog}
                            TransitionComponent={Transition}
                            disableEnforceFocus>
                            <DialogTitle>
                                <Box display={'flex'} flexDirection={'row'} width={'100%'} alignItems={'center'}>
                                    <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                                        {!this.state.allocationData ? "Add Employee" : "Add Resource to " + this.state.allocationData?.emp?.first_name + " " + this.state.allocationData?.emp?.last_name}
                                    </Typography>
                                    <IconButton onClick={handleClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </DialogTitle>
                            <DialogContent>
                                <UserAllocation
                                    type={'staff_location'}
                                    getDialogSize={this.getDialogSize}
                                    closeDialog={this.closeDialog}
                                    allocationData={this.state.allocationData}
                                    filters={
                                        {
                                            selectedRole: this.state.selectedRole,
                                            selectedSubSector: this.state.selectedSubSector,
                                            selectedOwnerRegion: this.state.selectedOwnerRegion,
                                            selectedOwnerDelivery: this.state.selectedOwnerDelivery,
                                            selectedCrm: this.state.selectedCrm,
                                            selectedEmployee: this.state.selectedEmployee
                                        }
                                    }
                                />
                            </DialogContent>
                        </Dialog>

                        <Dialog open={this.state.excelExportDialog} onClose={() => this.setState({ excelExportDialog: false })} >
                            <DialogContentText>
                                <Box margin={2}>
                                    The Report will be emailed to {user.email}
                                </Box>
                            </DialogContentText>
                            <DialogActions>
                                <Button onClick={() => this.setState({ excelExportDialog: false })} color="primary">
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Box>
                }
            </div >)
    }
}

export default withSnackbar(ResourceLocation)